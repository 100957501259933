import React from 'react';

const Switch = ({ formData, onFormDataChange }) => {
  const handleChange = (e) => {
    const { id, value } = e.target;
    onFormDataChange(id, value);
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="support_type">Support Type *</label>
              <select
                className="form-select form-select-sm"
                id="support_type"
                value={formData.support_type}
                onChange={handleChange}
              >
                <option value="">Select support type</option>
                <option value="PSC">PSC</option>
                <option value="MS GIRDER">MS GIRDER</option>
                <option value="TABULAR">TABULAR</option>
                <option value="FRP">FRP</option>
                <option value="RAIL">RAIL</option>
                <option value="PLINTH">PLINTH</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="structure_type">Structure Type *</label>
              <select
                className="form-select form-select-sm"
                id="structure_type"
                value={formData.structure_type}
                onChange={handleChange}
              >
                <option value="">Select structure type</option>
                <option value="Double Pole">Double Pole</option>
                <option value="Single Pole">Single Pole</option>
                <option value="Plinth">Plinth</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="switch_operation">Switch Operation *</label>
              <select
                className="form-select form-select-sm"
                id="switch_operation"
                value={formData.switch_operation}
                onChange={handleChange}
              >
                <option value="">Select switch operation</option>
                <option value="Off Load">Off Load</option>
                <option value="On Load">On Load</option>
                <option value="Not Installed">Not Installed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="ht_fuse_type">Insulator Type *</label>
              <select
                className="form-select form-select-sm"
                id="ht_fuse_type"
                value={formData.ht_fuse_type}
                onChange={handleChange}
              >
                <option value="">Select insulator type</option>
                <option value="Ceramic">Ceramic</option>
                <option value="Polymeric">Polymeric</option>
                <option value="Not Installed">Not Installed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="earthing_type">Earthing Type *</label>
              <select
                className="form-select form-select-sm"
                id="earthing_type"
                value={formData.earthing_type}
                onChange={handleChange}
              >
                <option value="">Select earthing type</option>
                <option value="GI Wire">GI Wire</option>
                <option value="GI Strip">GI Strip</option>
                <option value="Not Provided">Not Provided</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="lt_fuse_type">Switch Type *</label>
              <select
                className="form-select form-select-sm"
                id="lt_fuse_type"
                value={formData.lt_fuse_type}
                onChange={handleChange}
              >
                <option value="">Select LT fuse type</option>
                <option value="AB Switch">AB Switch</option>
                <option value="ABEB Switch">ABEB Switch</option>
                <option value="ABEBC Switch">ABEBC Switch</option>
                <option value="Changeover Switch">Changeover Switch</option>
                <option value="Not Installed">Not Installed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="loc_name">Location Name</label>
              <input
                placeholder="Enter location name"
                type="text"
                id="loc_name"
                className="form-control form-control-sm"
                value={formData.loc_name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="scheme">Scheme</label>
              <select
                className="form-select form-select-sm"
                id="scheme"
                value={formData.scheme}
                onChange={handleChange}
              >
                <option value="">Select scheme</option>
                <option value="ND">ND</option>
                <option value="DISS">DISS</option>
                <option value="DDUJGY">DDUJGY</option>
                <option value="IPDS">IPDS</option>
                <option value="KHUSHY">KHUSHY</option>
                <option value="SSVY">SSVY</option>
                <option value="ZP-SCSP">ZP-SCSP</option>
                <option value="RDSS">RDSS</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="comm_date">Commissioning Date</label>
              <input
                placeholder="Enter commissioning date"
                type="date"
                id="comm_date"
                className="form-control form-control-sm"
                value={formData.comm_date}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Switch;
