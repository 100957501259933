import React from 'react';

const RMU = ({ formData, onFormDataChange }) => {
  const handleChange = (e) => {
    const { id, value } = e.target;
    onFormDataChange(id, value);
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="structure_type">Structure Type *</label>
              <select
                className="form-select form-select-sm"
                id="structure_type"
                value={formData.structure_type}
                onChange={handleChange}
              >
                <option value="">Select structure type</option>
                <option value="Plinth">Plinth</option>
                <option value="Double Pole">Double Pole</option>
                <option value="Four Pole">Four Pole</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="earthing_type">Earthing Type *</label>
              <select
                className="form-select form-select-sm"
                id="earthing_type"
                value={formData.earthing_type}
                onChange={handleChange}
              >
                <option value="">Select earthing type</option>
                <option value="GI Wire">GI Wire</option>
                <option value="GI Strip">GI Strip</option>
                <option value="Not Provided">Not Provided</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="rmu_type">RMU Type *</label>
              <select
                className="form-select form-select-sm"
                id="rmu_type"
                value={formData.rmu_type}
                onChange={handleChange}
              >
                <option value="">Select RMU type</option>
                <option value="2 Way">2 Way</option>
                <option value="3 Way">3 Way</option>
                <option value="4 Way">4 Way</option>
                <option value="5 Way">5 Way</option>
                <option value="6 Way">6 Way</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="rmu_make_by">RMU Make By *</label>
              <select
                className="form-select form-select-sm"
                id="rmu_make_by"
                value={formData.rmu_make_by}
                onChange={handleChange}
              >
                <option value="">Select RMU make</option>
                <option value="ABB">ABB</option>
                <option value="CG Lucy">CG Lucy</option>
                <option value="C&S electric">C&S electric</option>
                <option value="Jyoti">Jyoti</option>
                <option value="Lucy">Lucy</option>
                <option value="Schneider">Schneider</option>
                <option value="Siemens">Siemens</option>
                <option value="Voltamp">Voltamp</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="scada_compatible">Scada Compatible *</label>
              <select
                className="form-select form-select-sm"
                id="scada_compatible"
                value={formData.scada_compatible}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="rmu_operation">Operation Type *</label>
              <select
                className="form-select form-select-sm"
                id="rmu_operation"
                value={formData.rmu_operation}
                onChange={handleChange}
              >
                <option value="">Select operation type</option>
                <option value="Manual">Manual</option>
                <option value="Motorized Local">Motorized Local</option>
                <option value="Motorized + Remote">Motorized + Remote</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_fencing">Fencing Available *</label>
              <select
                className="form-select form-select-sm"
                id="has_fencing"
                value={formData.has_fencing}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="loc_name">Location Name</label>
              <input
                placeholder="Enter location name"
                type="text"
                id="loc_name"
                className="form-control form-control-sm"
                value={formData.loc_name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="serial_no">Serial Number</label>
              <input
                placeholder="Enter serial no"
                type="text"
                id="serial_no"
                className="form-control form-control-sm"
                value={formData.serial_no}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="scheme">Scheme</label>
              <select
                className="form-select form-select-sm"
                id="scheme"
                value={formData.scheme}
                onChange={handleChange}
              >
                <option value="">Select scheme</option>
                <option value="ND">ND</option>
                <option value="DISS">DISS</option>
                <option value="DDUJGY">DDUJGY</option>
                <option value="IPDS">IPDS</option>
                <option value="KHUSHY">KHUSHY</option>
                <option value="SSVY">SSVY</option>
                <option value="ZP-SCSP">ZP-SCSP</option>
                <option value="RDSS">RDSS</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="comm_date">Commissioning Date</label>
              <input
                placeholder="Enter commissioning date"
                type="date"
                id="comm_date"
                className="form-control form-control-sm"
                value={formData.comm_date}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RMU;
