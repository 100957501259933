import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import '../../../components/css/Sidebar.css'

const RmuAddNetwork = ({ setRightSidebarVisible, selectedMarker, onSave, parentId, parentId1,getidInRighSidebar,onFormSave }) => {

  const [formData, setFormData] = useState({
    structure_type: (selectedMarker?.options?.eleProp?.properties?.point_props.support_type || ''),
    earthing_type: (selectedMarker?.options?.eleProp?.properties?.point_props.earthing_type || ''),
    rmu_type: (selectedMarker?.options?.eleProp?.properties?.point_props.rmu_type || ''),
    rmu_make_by: (selectedMarker?.options?.eleProp?.properties?.point_props.rmu_make_by || ''),
    scada_compatible: (selectedMarker?.options?.eleProp?.properties?.point_props.scada_compatible || ''),
    rmu_operation: (selectedMarker?.options?.eleProp?.properties?.point_props.rmu_operation || ''),
    has_fencing: (selectedMarker?.options?.eleProp?.properties?.point_props.has_fencing || ''),
    loc_name: (selectedMarker?.options?.eleProp?.properties?.point_props.loc_name || ''),
    serial_no: (selectedMarker?.options?.eleProp?.properties?.point_props.serial_no || ''),
    scheme: (selectedMarker?.options?.eleProp?.properties?.point_props.scheme || ''),
    position: (selectedMarker?.options?.eleProp?.properties?.line_props.position || ''),
    type: (selectedMarker?.options?.eleProp?.properties?.line_props.type || '')
  });

  const [showLoadAndStartButtons, setShowLoadAndStartButtons] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveAsCopyDisabled, setIsSaveAsCopyDisabled] = useState(true);

  // Load data from localStorage and check button visibility
  useEffect(() => {
    const savedData = localStorage.getItem('rmuFormData');
    setShowLoadAndStartButtons(!!savedData);
  }, []);

  // Update save buttons state based on form data
  useEffect(() => {
    const allFieldsFilled = Object.values(formData).every(value => value !== '');
    setIsSaveDisabled(!allFieldsFilled);
    setIsSaveAsCopyDisabled(!allFieldsFilled);
  }, [formData]);

  // Clear form data
  const clearForm = () => {
    setFormData({
      structure_type: '',
      earthing_type: '',
      rmu_type: '',
      rmu_make_by: '',
      scada_compatible: '',
      rmu_operation: '',
      has_fencing: '',
      loc_name: '',
      serial_no: '',
      scheme: '',
      position: '',
      type: ''
    });
    const savedData = localStorage.getItem('rmuFormData');
    setShowLoadAndStartButtons(!!savedData);
  };

  // Handle form changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const element = selectedMarker.options.eleProp;

    // Prepare the form data
    const requestBody = {
      "id": element.id,
      "type": "Feature",
      "geometry": element.geometry,
      "project_id": element.project_id,
      "properties": {
        "id": element.id,
        "parent_id": element.properties.parent_id,
        "point_type": "RMU",
        "point_props": {
          structure_type: formData.structure_type,
          earthing_type: formData.earthing_type,
          rmu_type: formData.rmu_type,
          rmu_make_by: formData.rmu_make_by,
          scada_compatible: formData.scada_compatible,
          rmu_operation: formData.rmu_operation,
          has_fencing: formData.has_fencing,
          loc_name: formData.loc_name,
          serial_no: formData.serial_no,
          scheme: formData.scheme,
        },
        "line_props": {
          position: formData.position,
          type: formData.type
        },
        "data_type": "ht_location"
      }
    };
    onFormSave();
    onSave(requestBody);
    toast.success("Data Save Successfully!")
    setRightSidebarVisible(false)
    clearForm();
    // const myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: JSON.stringify(requestBody),
    //   redirect: "follow"
    // };

    // try {
    //   const response = await fetch("https://geo-tag.nividasoftware.com/api/create-new-network", requestOptions);
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }
    //   const result = await response.json();
    //   console.log("Response:", result);
    //   toast.success("Data Saved Successfully!");
    //   setRightSidebarVisible(false);
    //   clearForm(); // Clear the form after successful submission
    // } catch (error) {
    //   console.error("Error:", error);
    //   toast.error("Failed to save data. Please try again.");
    // }
  };

  // Handle save as copy
  const handleSaveAsCopy = () => {
    if (!isSaveAsCopyDisabled) {
      localStorage.setItem('rmuFormData', JSON.stringify(formData));
       // Clear the form after saving as copy
    }
  };

  // Handle load last copy
  const handleLoadLastCopy = () => {
    const savedData = localStorage.getItem('rmuFormData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  };

  // Handle Clear
  const handleStartNew = () => {
    clearForm();
  };

  return (
    <div>
      {/* Show Load Last Copy and Clear buttons if there is saved data */}
      {showLoadAndStartButtons && (
        <div className="mb-3">
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
              marginRight: '10px'
            }}
            onClick={handleLoadLastCopy}
          >
            Load Last Copy
          </button>
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
            }}
            onClick={handleStartNew}
          >
            Clear
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="structure_type">Structure Type *</label>
              <select
                className="form-select form-select-sm"
                id="structure_type"
                value={formData.structure_type}
                onChange={handleChange}
              >
                <option value="">Select structure type</option>
                <option value="Plinth">Plinth</option>
                <option value="Double Pole">Double Pole</option>
                <option value="Four Pole">Four Pole</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="earthing_type">Earthing Type *</label>
              <select
                className="form-select form-select-sm"
                id="earthing_type"
                value={formData.earthing_type}
                onChange={handleChange}
              >
                <option value="">Select earthing type</option>
                <option value="GI Wire">GI Wire</option>
                <option value="GI Strip">GI Strip</option>
                <option value="Not Provided">Not Provided</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="rmu_type">RMU Type *</label>
              <select
                className="form-select form-select-sm"
                id="rmu_type"
                value={formData.rmu_type}
                onChange={handleChange}
              >
                <option value="">Select RMU type</option>
                <option value="2 Way">2 Way</option>
                <option value="3 Way">3 Way</option>
                <option value="4 Way">4 Way</option>
                <option value="5 Way">5 Way</option>
                <option value="6 Way">6 Way</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="rmu_make_by">RMU Make By *</label>
              <select
                className="form-select form-select-sm"
                id="rmu_make_by"
                value={formData.rmu_make_by}
                onChange={handleChange}
              >
                <option value="">Select RMU make</option>
                <option value="ABB">ABB</option>
                <option value="CG Lucy">CG Lucy</option>
                <option value="C&S electric">C&S electric</option>
                <option value="Jyoti">Jyoti</option>
                <option value="Lucy">Lucy</option>
                <option value="Schneider">Schneider</option>
                <option value="Siemens">Siemens</option>
                <option value="Voltamp">Voltamp</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="scada_compatible">Scada Compatible *</label>
              <select
                className="form-select form-select-sm"
                id="scada_compatible"
                value={formData.scada_compatible}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="rmu_operation">Operation Type *</label>
              <select
                className="form-select form-select-sm"
                id="rmu_operation"
                value={formData.rmu_operation}
                onChange={handleChange}
              >
                <option value="">Select operation type</option>
                <option value="Manual">Manual</option>
                <option value="Motorized Local">Motorized Local</option>
                <option value="Motorized + Remote">Motorized + Remote</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_fencing">Has Fencing *</label>
              <select
                className="form-select form-select-sm"
                id="has_fencing"
                value={formData.has_fencing}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="loc_name">Location Name *</label>
              <input
                placeholder="Enter location name"
                type="text"
                id="loc_name"
                className="form-control form-control-sm"
                value={formData.loc_name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="serial_no">Serial No *</label>
              <input
                placeholder="Enter serial number"
                type="text"
                id="serial_no"
                className="form-control form-control-sm"
                value={formData.serial_no}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="scheme">Scheme *</label>
              <select
                className="form-select form-select-sm"
                id="scheme"
                value={formData.scheme}
                onChange={handleChange}
              >
                <option value="">Select scheme</option>
                <option value="Scheme1">Scheme1</option>
                <option value="Scheme2">Scheme2</option>
                <option value="Scheme3">Scheme3</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="position">Position *</label>
              <select
                className="form-select form-select-sm"
                id="position"
                value={formData.position}
                onChange={handleChange}
              >
                <option value="">Select position</option>
                <option value="Top">Top</option>
                <option value="Bottom">Bottom</option>
                <option value="Middle">Middle</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="type">Type</label>
              <input
                placeholder="Enter type"
                type="text"
                id="type"
                className="form-control form-control-sm"
                value={formData.type}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            type="button"
            className="save-button"
            onClick={handleSaveAsCopy}
            disabled={isSaveAsCopyDisabled}
          >
            Save as Copy
          </button>
          <button
            type="submit"
            className="save-button"
            onClick={handleSubmit}
            disabled={isSaveDisabled}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default RmuAddNetwork;
