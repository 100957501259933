import React, { useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import leaflet CSS
import L from 'leaflet';
import { Modal, Button } from 'react-bootstrap'; // Import Modal from react-bootstrap

// GeoJSON Data
const geojsonData = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "172656375798057ms5iovo",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [73.22448313236238, 23.224756185483887] // Note: Longitude first, then Latitude
            },
            "project_id": 12073,
            "properties": {
                "id": "172656375798057ms5iovo",
                "parent_id": 641562,
                "point_type": "HT Pole",
                "point_props": {
                    "support_type": "PSC",
                    "structure_type": "Single Pole",
                    "pole_type": "Pin Pole",
                    "earthing_type": "GI Wire",
                    "insulator_type": "Ceramic",
                    "scheme": "ND"
                },
                "line_props": {
                    "position": "Overhead",
                    "type": "test"
                },
                "data_type": "ht_location",
                 "status": "submitted"
            },
            "isValid": true
        },
        {
            "id": "17265637596864iekcpexp",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [73.22490423917772, 23.2249533738435]
            },
            "project_id": 12073,
            "properties": {
                "id": "17265637596864iekcpexp",
                "parent_id": "172656375798057ms5iovo",
                "point_type": "HT Pole",
                "point_props": {
                    "support_type": "PSC",
                    "structure_type": "Single Pole",
                    "pole_type": "Pin Pole",
                    "earthing_type": "GI Wire",
                    "insulator_type": "Ceramic",
                    "scheme": "ND"
                },
                "line_props": {
                    "position": "Overhead",
                    "type": "test"
                },
                "data_type": "ht_location",
                "status": "validated"
            },
            "isValid": true
        },
        {
            "id": "1726563761260ljc1umntg",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [73.22529584169389, 23.225116054020965]
            },
            "project_id": 12073,
            "properties": {
                "id": "1726563761260ljc1umntg",
                "parent_id": "17265637596864iekcpexp",
                "point_type": "HT Pole",
                "point_props": {
                    "support_type": "PSC",
                    "structure_type": "Single Pole",
                    "pole_type": "Pin Pole",
                    "earthing_type": "GI Wire",
                    "insulator_type": "Ceramic",
                    "scheme": "ND"
                },
                "line_props": {
                    "position": "Overhead",
                    "type": "test"
                },
                "data_type": "ht_location",
                 "status": "submitted"
            },
            "isValid": true
        }
    ]
};

// Convert points to a LineString feature
const convertPointsToLines = (features) => {
    const coordinates = features.map(feature => feature.geometry.coordinates);
    return {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": coordinates
        },
        "properties": {}
    };
};

// Create a GeoJSON object with lines
const geojsonWithLines = {
    "type": "FeatureCollection",
    "features": [
        ...geojsonData.features,
        convertPointsToLines(geojsonData.features)
    ]
};

const pointStyle = {
    color: '#3388ff',
    weight: 5
};

const lineStyle = {
    color: '#ffdb58',
    weight: 3,
    opacity: 3
};

const style = (feature) => {
    switch (feature.geometry.type) {
        case 'LineString':
            return lineStyle;
        case 'Point':
            return pointStyle;
        default:
            return { color: '#000', weight: 1 }; // Default style
    }
};

const onEachFeature = (feature, layer, setSelectedFeature) => {
    layer.on({
        click: () => {
            setSelectedFeature(feature.properties);
        }
    });
};

const ViewTaskMap = () => {
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [showModal, setShowModal] = useState(false);

    // Open the modal when a feature is clicked
    const handleFeatureClick = (properties) => {
        setSelectedFeature(properties);
        setShowModal(true);
    };

    return (
        <>
            <MapContainer
                center={[23.224756185483887, 73.22448313236238]} // Latitude, Longitude
                zoom={15}
                style={{ height: '100vh', width: '100%' }}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <GeoJSON
                    data={geojsonWithLines}
                    onEachFeature={(feature, layer) => onEachFeature(feature, layer, handleFeatureClick)}
                    style={style}
                />
            </MapContainer>

            {/* Modal for showing marker details */}
            {selectedFeature && (
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Marker Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>ID:</strong> {selectedFeature.id}</p>
                        <p><strong>Point Type:</strong> {selectedFeature.point_type}</p>
                        <h5>Point Properties</h5>
                        <ul>
                            {Object.entries(selectedFeature.point_props || {}).map(([key, value]) => (
                                <li key={key}><strong>{key}:</strong> {value}</li>
                            ))}
                        </ul>
                        <h5>Line Properties</h5>
                        <ul>
                            {Object.entries(selectedFeature.line_props || {}).map(([key, value]) => (
                                <li key={key}><strong>{key}:</strong> {value}</li>
                            ))}
                        </ul>
                        <p><strong>Data Type:</strong> {selectedFeature.data_type}</p>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        {selectedFeature.status === 'submitted' && (
                            <Button variant="primary" onClick={() => alert('Validated!')}>
                                Validate
                            </Button>
                        )}
                        {selectedFeature.status === 'validated' && (
                            <Button variant="success" onClick={() => alert('Approved!')}>
                                Approve
                            </Button>
                        )}
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ViewTaskMap;
