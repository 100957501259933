import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import '../../../components/css/Sidebar.css'

const CtptAddNetwork = ({ setRightSidebarVisible, selectedMarker, onSave, parentId, parentId1,getidInRighSidebar,onFormSave }) => {
  const [formData, setFormData] = useState({
    support_type: (selectedMarker?.options?.eleProp?.properties?.point_props.support_type || ''),
    structure_type: (selectedMarker?.options?.eleProp?.properties?.point_props.structure_type || ''),
    earthing_type: (selectedMarker?.options?.eleProp?.properties?.point_props.earthing_type || ''),
    ht_fuse_type: (selectedMarker?.options?.eleProp?.properties?.point_props.ht_fuse_type || ''),
    ctpt_type: (selectedMarker?.options?.eleProp?.properties?.point_props.ctpt_type || ''),
    ctpt_ratio: (selectedMarker?.options?.eleProp?.properties?.point_props.ctpt_ratio || ''),
    has_fencing: (selectedMarker?.options?.eleProp?.properties?.point_props.has_fencing || ''),
    scheme: (selectedMarker?.options?.eleProp?.properties?.point_props.scheme || ''),
    position: (selectedMarker?.options?.eleProp?.properties?.line_props.position || ''),
    type: (selectedMarker?.options?.eleProp?.properties?.line_props.type || ''),
  });

  const [showLoadAndStartButtons, setShowLoadAndStartButtons] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveAsCopyDisabled, setIsSaveAsCopyDisabled] = useState(true);

  // Load data from localStorage and check button visibility
  useEffect(() => {
    const savedData = localStorage.getItem('ctptAddNetworkData');
    setShowLoadAndStartButtons(!!savedData);
  }, []);

  // Update save buttons state based on form data
  useEffect(() => {
    const allFieldsFilled = Object.values(formData).every(value => value !== '');
    setIsSaveDisabled(!allFieldsFilled);
    setIsSaveAsCopyDisabled(!allFieldsFilled);
  }, [formData]);

  // Clear form data
  const clearForm = () => {
    setFormData({
      support_type: '',
      structure_type: '',
      earthing_type: '',
      ht_fuse_type: '',
      ctpt_type: '',
      ctpt_ratio: '',
      has_fencing: '',
      scheme: '',
      position: '', // Added field
      type: '' // Added field
    });
    const savedData = localStorage.getItem('ctptAddNetworkData');
    setShowLoadAndStartButtons(!!savedData);
  };

  // Handle form changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const element = selectedMarker.options.eleProp;
    // Prepare the form data
    const requestBody = {
      "id": element.id,
      "type": "Feature",
      "geometry": element.geometry,
      "project_id": element.project_id,
      "properties": {
        "id": element.id,
        "parent_id": element.properties.parent_id,
        "point_type": "CTPT",
        "point_props": {
          support_type: formData.support_type,
          structure_type: formData.structure_type,
          earthing_type: formData.earthing_type,
          ht_fuse_type: formData.ht_fuse_type,
          ctpt_type: formData.ctpt_type,
          ctpt_ratio: formData.ctpt_ratio,
          has_fencing: formData.has_fencing,
          scheme: formData.scheme
        },
        "line_props": {
          position: formData.position,
          type: formData.type
        },
        "data_type": "ht_location"
      }
    };
    onFormSave();
    onSave(requestBody);
    toast.success("Data Save Successfully!")
    setRightSidebarVisible(false)
    clearForm();
    // const myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: JSON.stringify(requestBody),
    //   redirect: "follow"
    // };

    // try {
    //   const response = await fetch("https://geo-tag.nividasoftware.com/api/create-new-network", requestOptions);
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }
    //   const result = await response.json();
    //   console.log("Response:", result);
    //   toast.success("Data Saved Successfully!");
    //   setRightSidebarVisible(false);
    //   clearForm(); // Clear the form after successful submission
    // } catch (error) {
    //   console.error("Error:", error);
    //   toast.error("Failed to save data. Please try again.");
    // }
  };

  // Handle save as copy
  const handleSaveAsCopy = () => {
    if (!isSaveAsCopyDisabled) {
      localStorage.setItem('ctptAddNetworkData', JSON.stringify(formData));
       // Clear the form after saving as copy
    }
  };

  // Handle load last copy
  const handleLoadLastCopy = () => {
    const savedData = localStorage.getItem('ctptAddNetworkData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  };

  // Handle Clear
  const handleStartNew = () => {
    clearForm();
  };

  return (
    <div>
      {/* Show Load Last Copy and Clear buttons if there is saved data */}
      {showLoadAndStartButtons && (
        <div className="mb-3">
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
              marginRight: '10px'
            }}
            onClick={handleLoadLastCopy}
          >
            Load Last Copy
          </button>
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
            }}
            onClick={handleStartNew}
          >
            Clear
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="support_type">Support Type *</label>
              <select
                className="form-select form-select-sm"
                id="support_type"
                value={formData.support_type}
                onChange={handleChange}
              >
                <option value="">Select support type</option>
                <option value="PSC">PSC</option>
                <option value="MS GIRDER">MS GIRDER</option>
                <option value="TABULAR">TABULAR</option>
                <option value="FRP">FRP</option>
                <option value="RAIL">RAIL</option>
                <option value="PLINTH">PLINTH</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="structure_type">Structure Type *</label>
              <select
                className="form-select form-select-sm"
                id="structure_type"
                value={formData.structure_type}
                onChange={handleChange}
              >
                <option value="">Select structure type</option>
                <option value="Double Pole">Double Pole</option>
                <option value="Single Pole">Single Pole</option>
                <option value="Plinth">Plinth</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="earthing_type">Earthing Type *</label>
              <select
                className="form-select form-select-sm"
                id="earthing_type"
                value={formData.earthing_type}
                onChange={handleChange}
              >
                <option value="">Select earthing type</option>
                <option value="GI Wire">GI Wire</option>
                <option value="GI Strip">GI Strip</option>
                <option value="Not Provided">Not Provided</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="ht_fuse_type">HT Fuse Type *</label>
              <select
                className="form-select form-select-sm"
                id="ht_fuse_type"
                value={formData.ht_fuse_type}
                onChange={handleChange}
              >
                <option value="">Select HT fuse type</option>
                <option value="Drop Out Fuse">Drop Out Fuse</option>
                <option value="Rewirable Fuse">Rewirable Fuse</option>
                <option value="HRC Fuse">HRC Fuse</option>
                <option value="Not Installed">Not Installed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="ctpt_type">CTPT Type *</label>
              <select
                className="form-select form-select-sm"
                id="ctpt_type"
                value={formData.ctpt_type}
                onChange={handleChange}
              >
                <option value="">Select CTPT type</option>
                <option value="Oil Type">Oil Type</option>
                <option value="DRY Type">DRY Type</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="ctpt_ratio">CTPT Ratio *</label>
              <select
                className="form-select form-select-sm"
                id="ctpt_ratio"
                value={formData.ctpt_ratio}
                onChange={handleChange}
              >
                <option value="">Select CTPT ratio</option>
                <option value="5-5">5-5</option>
                <option value="10-5">10-5</option>
                <option value="15-5">15-5</option>
                <option value="20-5">20-5</option>
                <option value="25-5">25-5</option>
                <option value="30-5">30-5</option>
                <option value="40-5">40-5</option>
                <option value="50-5">50-5</option>
                <option value="75-5">75-5</option>
                <option value="100-5">100-5</option>
                <option value="150-5">150-5</option>
                <option value="200-5">200-5</option>
                <option value="250-5">250-5</option>
                <option value="300-5">300-5</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_fencing">Fencing Available *</label>
              <select
                className="form-select form-select-sm"
                id="has_fencing"
                value={formData.has_fencing}
                onChange={handleChange}
              >
                <option value="">Select fencing availability</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="scheme">Scheme *</label>
              <input
                placeholder="Enter Scheme"
                type="text"
                id="scheme"
                className="form-control form-control-sm"
                value={formData.scheme}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="position">Position *</label>
              <select
                className="form-select form-select-sm"
                id="position"
                value={formData.position}
                onChange={handleChange}
              >
                <option value="">Select position</option>
                <option value="Overhead">Overhead</option>
                <option value="Underground">Underground</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="type">Type *</label>
              <input
                placeholder="Enter Type"
                type="text"
                id="type"
                className="form-control form-control-sm"
                value={formData.type}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            type="button"
            className="save-button"
            onClick={handleSaveAsCopy}
            disabled={isSaveAsCopyDisabled}
          >
            Save as Copy
          </button>
          <button
            type="submit"
            className="save-button"
            disabled={isSaveDisabled}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default CtptAddNetwork;
