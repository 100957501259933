// Sidebar.js
import React, { useState, useEffect, useRef } from "react";
import "./css/Sidebar.css";
import { isEqual } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import Transformer from "../forms/transformer/Transformer";
import Switch from "../forms/switch/Switch";
import HT_pole from "../forms/ht_pole/HT_pole";
import Fuse from "../forms/fuse/Fuse";
import Ctpt from "../forms/ctpt/Ctpt";
import Gentry from "../forms/gentry/Gentry";
import HT_routePoint from "../forms/ht_route_point/HT_routePoint";
import RMU from "../forms/rmu/RMU";
import TransformerAddNetwork from "../forms/transformer/transformer_add_network/TransformerAddNetwork";
import SwitchAddNetwork from "../forms/switch/switch_add_network/SwitchAddNetwork";
import HtPoleAddNetwork from "../forms/ht_pole/ht_pole_add_network/HtPoleAddNetwork";
import FuseAddNetwork from "../forms/fuse/fuse_add_network/FuseAddNetwork";
import CtptAddNetwork from "../forms/ctpt/ctpt_add_network/CtptAddNetwork";
import GentryAddNetwork from "../forms/gentry/gentry_add_network/GentryAddNetwork";
import RmuAddNetwork from "../forms/rmu/rmu_add_network/RmuAddNetwork";
import HtRoutePoint from "../forms/ht_route_point/ht_route_add_network/HtRoutePoint";

const RightSidebar = ({
    selectedFeature,
    setOpenedMenuOption,
    totalDistance1,
    sendTracingData,
    networkDetails,
    getidInRighSidebar,
    images,
    handleImageSelect,
    selectedMarker,
    clearImageFromMarker,
    clearLastLine,
    lineProps,
    selectDoubleImage,
    setDataOnHandleEditClick,
    setIsEditing,
    isEditing,
    dataOnHandleEditClick,
    setRightSidebarVisible,
    clearNetwork,
    addChildNetwork,
    getParentChildRelatedFeatures,
    parentId,
    parentId1,
    updateNewNetworkPayloadById,
    finalNetworkPayload,
    resetMapview
}) => {
    const [isClassAdded, setIsClassAdded] = useState(false);
    const [opendOption, setOpendOption] = useState(setOpenedMenuOption);
    const [tracingDataNew, sendTracingDataNew] = useState(false);
    const [savedFormData, setSavedFormData] = useState(false);
    const [selectedLOcation, setSelectedLOcation] = useState(null);
    const [cosumers, setCosumers] = useState([]);
    const [formData, setFormData] = useState({});
    const [initialFormData, setInitialFormData] = useState({});
    const [featureParentChild, setFeatureParentChild] = useState({ parent: null, children: [] });
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [editedProperties, setEditedProperties] = useState({
        position: '',
        type: ''
    });
    const [htRoutePointData, setHtRoutePointData] = useState(null);
    const [rmuData, setRmuData] = useState(null);
    const [ctptData, setCtptData] = useState(null);
    const [fuseData, setFuseData] = useState(null);
    const [gentryData, setGentryData] = useState(null);
    const [htPoleData, setHtPoleData] = useState(null);
    const [switchData, setSwitchData] = useState(null);
    const [transformerData, setTransformerData] = useState(null);
    const [formCompletion, setFormCompletion] = useState({
        fuseAddNetwork: false,
        ctptForm: false,
        transformer: false,
        switch: false,
        htPole: false,
        gentry: false,
        htRoute: false,
        rmu: false
        // Add other forms here
    });

    // useEffect(()=>{
    //     console.log(formCompletion,"formCompletion")
    // },[formCompletion])

    const handleFormCompletion = (formName, isComplete) => {
        setFormCompletion(prev => ({
            ...prev,
            [formName]: isComplete,
        }));
    };

    // Check if all forms are completed
    const allFormsCompleted = finalNetworkPayload.length > 0 ? true : false;
    const handleNetworkElementSave = (data) => {
        data.isValid = true;
        updateNewNetworkPayloadById(data.id, data);
    }

    /*const handleHtRoutePointSave = (data) => {
        console.log(data);
        setHtRoutePointData(data);
    };

    const handleRmuSave = (data) => {
        console.log(data);
        setRmuData(data);
    };

    const handleCtptSave = (data) => {
        console.log(data);
        setCtptData(data);
    };

    const handleFuseSave = (data) => {
        console.log(data);
        setFuseData(data);
    };

    const handleGentrySave = (data) => {
        console.log(data);
        setGentryData(data);
    };

    const handleHtPoleSave = (data) => {
        console.log(data);
        setHtPoleData(data);
    };

    const handleswitchSave = (data) => {
        console.log(data);
        setSwitchData(data);
    };

    const handleTransformerSave = (data) => {
        console.log(data);
        //updateNewNetworkPayloadById();
        setTransformerData(data);
    };*/

    const handleSubmitNetwork = async () => {
        if (allFormsCompleted !== null) {
            // Handle network submission
            console.log("All elements are completed. Submitting network...");
        }

        /*const networkData = {
            htRoutePointData,
            rmuData,
            ctptData,
            fuseData,
            gentryData,
            htPoleData,
            transformerData,
            switchData
        };

        const filteredNetworkData = Object.fromEntries(
            Object.entries(networkData).filter(([_, value]) => value !== null)
        );

        const payloadArray = Object.values(filteredNetworkData).map(item => {
            // console.log(payloadArray,"payloadArray")
            return {
                id: item.id,
                type: item.type,
                geometry: item.geometry,
                project_id: item.project_id,
                properties: {
                    id: item.properties.id,
                    parent_id: item.properties.parent_id,
                    point_type: item.properties.point_type,
                    point_props: item.properties.point_props,
                    line_props: item.properties.line_props || '',
                    "data_type": "ht_location"
                }
            };
        });

        console.log("Payload Array:", JSON.stringify(payloadArray, null, 2));*/
        // console.log("Network Data:", JSON.stringify(filteredNetworkData, null, 2));
        /*const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json"); 

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({finalNetworkPayload}),
            redirect: "follow"
        };

        console.log("Request Options:", requestOptions);*/ // Pretty print for readability
        const formdatas = new FormData();
        formdatas.append("network", JSON.stringify(finalNetworkPayload));

        const requestOptions = {
            method: "POST",
            // headers: {
            //   Authorization: `Bearer ${authDetails._token}`,
            // },
            body: formdatas,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                `/api/create-new-network`,
                requestOptions
            );
            const result = await response.json();
            if (result?.data) {
                resetMapview();
                toast.success("Successfully created new network task!", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "custom-toast",
                });
            } else {
                toast.error("Failed to create network task.", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "custom-toast",
                });
            }
        } catch (error) {
            toast.error("Error fetching details.", {
                position: toast.POSITION.TOP_RIGHT,
                className: "custom-toast",
            });
        }
    };

    const handleImageClick = (image) => {
        if (selectedMarker) {
            handleImageSelect(image, selectedMarker); // Pass the selected marker to the handleImageSelect function
        }
    };

    useEffect(() => {
        // Set form data based on selectedFeature
        if (selectedFeature) {
            setDataOnHandleEditClick(false)
            setFormData(selectedFeature);
            setFeatureParentChild(getParentChildRelatedFeatures(selectedFeature.element_id));
        }
    }, [selectedFeature]);

    const handleFormDataChange = (id, value) => {
        setFormData(prevData => ({
            ...prevData,
            [id]: value,
        }));
    };

    useEffect(() => {
        var tabName = setOpenedMenuOption ? setOpenedMenuOption.tab : null;
        setOpendOption(tabName);
        if (
            tabName === "properties" || tabName === "new network properties" ||
            tabName === "line-properties" ||
            tabName === "tracing" ||
            tabName === "sld" ||
            tabName === "add-network-button"
        )
        // setDataOnHandleEditClick(false)
        setIsClassAdded(true);
        async function fetchData() {
            let authDetails = JSON.parse(localStorage.getItem("_auth"));
            if (typeof authDetails?._token !== "undefined") {
                const formdata = new FormData();
                formdata.append("project_id", getidInRighSidebar.project_id);
                formdata.append("location_id", selectedFeature.element_id);

                const requestOptions = {
                    method: "POST",
                    body: formdata,
                    redirect: "follow",
                };
                fetch(
                    process.env.REACT_APP_API_URL +
                    `/api/get-cuonsumer-details`,
                    requestOptions
                )
                .then((response) => response.json())
                .then((result) => {
                    setCosumers(result?.data || []);
                })
                .catch((error) => {
                    console.log("first")
                });
            }
        }
        // get consumer details
        if (tabName === "sld") {
            setCosumers({});
            setSelectedLOcation(selectedFeature);
            fetchData();
        }
    }, [setOpenedMenuOption]);

    useEffect(() => {
        if (dataOnHandleEditClick) {
            // Set the initial form data when editing starts
            setInitialFormData(formData);
        }
    }, [dataOnHandleEditClick]);

    useEffect(() => {
        // Perform a deep comparison of formData and initialFormData
        const isFormChanged = !isEqual(formData, initialFormData);
        setIsSaveButtonDisabled(!isFormChanged);
    }, [formData, initialFormData]);

    const handleEditClick = () => {
        setDataOnHandleEditClick(true);
    };

    const handleEditClickSave = async () => {
        setSavedFormData(formData);
        let authDetails = JSON.parse(localStorage.getItem("_auth"));
        if (authDetails && authDetails._token) {
            const combinedData = {
                ...formData,
                project_id: getidInRighSidebar.project_id,
                element_id: selectedFeature.element_id,
            };

            const formdatas = new FormData();
            formdatas.append("point_props", JSON.stringify(combinedData));

            const requestOptions = {
                method: "POST",
                // headers: {
                //   Authorization: `Bearer ${authDetails._token}`,
                // },
                body: formdatas,
                redirect: "follow",
            };

            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                    `/api/update-point-properties/${selectedFeature.element_id}`,
                    requestOptions
                );
                const result = await response.json();
                if (result?.data) {
                    toast.success("Successfully updated point properties!", {
                        position: toast.POSITION.TOP_RIGHT,
                        className: "custom-toast",
                    });
                    setDataOnHandleEditClick(false);
                    setFormData(JSON.parse(result.data));
                } else {
                    toast.error("Failed to update point properties.", {
                        position: toast.POSITION.TOP_RIGHT,
                        className: "custom-toast",
                    });
                }
            } catch (error) {
                toast.error("Error fetching consumer details.", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "custom-toast",
                });
            }
        }
    };

    const handleEditClickCancel = () => {
        setDataOnHandleEditClick(false);
        setFormData(initialFormData); // Reset form data to initial state on cancel
    };

    const handleTracing = (type) => {
        sendTracingData(type);
        setIsClassAdded(true);
        if (type === "start" || type === "end") setIsClassAdded(false);
    };

    const rightSubmenuRef = useRef(null);

    const removeClass = () => {
        if (rightSubmenuRef.current) {
            setIsClassAdded(false);
            //rightSubmenuRef.current.classList.remove('visible');
        }
    };

    const handleCallApi = () => {
        sendTracingDataNew(true);
    };

    const openRightSubmenu = (tabName) => {
        const anchor = document.querySelector(".leaflet-popup-close-button");
        if (anchor) {
            anchor.click();
        }
        if (opendOption === tabName && isClassAdded === true) {
            setIsClassAdded(false);
        } else if (opendOption !== tabName && isClassAdded === true) {
            //
        } else {
            setIsClassAdded((prevState) => !prevState);
        }
        setOpendOption(tabName);
    };

    let solarMeterSum = 0;
    let solarLoadSum = 0;
    let LoadSum = 0;
    let Consumers = 0;
    if (Array.isArray(cosumers)) {
        cosumers.forEach((item) => {
            solarMeterSum += Number(item?.solar_meters) || 0;
            solarLoadSum += Number(item?.soalr_load) || 0;
            LoadSum += Number(item?.load) || 0;
            Consumers += Number(item?.consumers) || 0;
        });
    }

    const renderSummary = () => (
        // New Stucture
        <tr>
            <td>
                <b>Solar</b>
                <span className="d-block">{solarMeterSum} Nos</span>
                <span className="d-block">{solarLoadSum} KW</span>
            </td>
            <td className="text-center">
                <b>Consumers</b>
                {Array.isArray(cosumers) && (
                    <span className="d-block">{Consumers} Nos</span>
                )}
                {Array.isArray(cosumers) && (
                    <span className="d-block">{LoadSum} KW</span>
                )}
            </td>
        </tr>
    );

    const printIfBoolean = (variable) => {
        if (typeof variable === "boolean") {
            return variable.toString();
        }

        return variable;
    };

    const handleEditClickLine = () => {
        setIsEditing(true);
    };

    const handleCloseClick = () => {
        setIsEditing(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedProperties({
            ...editedProperties,
            [name]: value
        });
    };

    return (
        <div className="rightsidebar">
            {isClassAdded ? (
                <div
                    ref={rightSubmenuRef}
                    className={`sub-sidebar-wrap sub-menu ${opendOption === "sld" ? (selectedFeature?.rmu_type === '4 Way' ? 'ex-lg' : "lg") : ""
                        } ${isClassAdded ? "visible" : ""}`}
                >
                    {selectedFeature ? (
                        <>
                            <ul
                                data-parent="properties-bar"
                                className={`${opendOption === "properties"
                                    ? "d-block"
                                    : "d-none"
                                    }`}
                            >
                                <li>
                                    <h6 className="sub-menu-title mb-0">
                                        Properties
                                    </h6>
                                </li>
                                {dataOnHandleEditClick === true ? (
                                    <>
                                        {opendOption === "properties" && selectedFeature?.point_name === "Transformer" ? (
                                            <Transformer formData={formData} onFormDataChange={handleFormDataChange} />
                                        ) : selectedFeature?.point_name ? (
                                            <Switch formData={formData} onFormDataChange={handleFormDataChange} />
                                        ) : selectedFeature?.point_name ? (
                                            <HT_pole formData={formData} onFormDataChange={handleFormDataChange} />
                                        ) : selectedFeature?.point_name ? (
                                            <Fuse formData={formData} onFormDataChange={handleFormDataChange} />
                                        ) : selectedFeature?.point_name ? (
                                            <Ctpt formData={formData} onFormDataChange={handleFormDataChange} />
                                        ) : selectedFeature?.point_name ? (
                                            <Gentry formData={formData} onFormDataChange={handleFormDataChange} />
                                        ) : selectedFeature?.point_name ? (
                                            <HT_routePoint formData={formData} onFormDataChange={handleFormDataChange} />
                                        ) : selectedFeature?.point_name ? (
                                            <RMU formData={formData} onFormDataChange={handleFormDataChange} />
                                        ) : ""}
                                    </>
                                ) : (
                                    Object.keys(formData).map(
                                        (innerAttr, index) => {
                                            if (formData[innerAttr] === null)
                                                formData[innerAttr] = "N/A"; // Handling null values
                                            return (
                                                <>
                                                    <ul
                                                        className="sub-menu-child"
                                                        key={index}
                                                    >
                                                        <li className="label-name">
                                                            {innerAttr.replace(
                                                                /_/g,
                                                                " "
                                                            )}
                                                        </li>
                                                        <li>
                                                            {
                                                                formData[
                                                                innerAttr
                                                                ]
                                                            }
                                                        </li>
                                                    </ul>
                                                </>
                                            );
                                        }
                                    )
                                )}
                                {dataOnHandleEditClick === false ? (
                                    <button
                                        style={{
                                            padding: "8px 30px",
                                            backgroundColor: "#427d9d",
                                            borderRadius: "8px",
                                            color: "white",
                                            fontSize: "12px",
                                            marginTop: "20px",
                                        }}
                                        onClick={handleEditClick}
                                    >
                                        Edit
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            className="save-button"
                                            style={{
                                                padding: "8px 30px",
                                                backgroundColor: "#427d9d",
                                                borderRadius: "8px",
                                                color: "white",
                                                fontSize: "12px",
                                                marginTop: "20px",
                                            }}
                                            onClick={handleEditClickSave}
                                            disabled={isSaveButtonDisabled}
                                        >
                                            Save
                                        </button>
                                        <button
                                            style={{
                                                padding: "8px 30px",
                                                backgroundColor: "white",
                                                borderRadius: "8px",
                                                color: "black",
                                                fontSize: "12px",
                                                marginTop: "20px",
                                                marginLeft: "10px",
                                                border: "1px solid black",
                                            }}
                                            onClick={handleEditClickCancel}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                )}
                            </ul>
                            {/* SLD Code: Start */}
                            {/* Transformer SLD */}
                            {selectedFeature?.point_name === "Transformer" ? (
                                <ul
                                    data-parent="sld-bar"
                                    className={`${opendOption === "sld"
                                        ? "d-block"
                                        : "d-none"
                                        }`}
                                >
                                    <li>
                                        <h6 className="sub-menu-title mb-0">
                                            SLD view
                                        </h6>
                                    </li>
                                    <li>
                                        <table
                                            cellPadding={0}
                                            cellSpacing={0}
                                            className="diagram-tbl-chart mx-auto mt-3"
                                        >
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <hr className="m-0" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src="sld/new_image/LA-New.svg"
                                                                style={{
                                                                    "padding-right":
                                                                        "0",
                                                                    width: "50%",
                                                                }}
                                                                className="mx-auto d-block"
                                                                alt="LA"
                                                                title="LA 230V"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td valign="middle">
                                                        <label>LA</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src="sld/teletransmitted_switches/fuse_active.svg"
                                                                className="mx-auto d-block"
                                                                alt="fuse"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td valign="middle">
                                                        <label>HT Fuse</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src="sld/new_image/transformer.svg"
                                                                className="mx-auto d-block"
                                                                alt="Transformer"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <label>
                                                            <span>
                                                                Transformer
                                                            </span>
                                                            <br />
                                                            {selectedFeature?.xmr_capacity ??
                                                                0}{" "}
                                                            KVA
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src="sld/teletransmitted_switches/Fuse.svg"
                                                                className="mx-auto d-block"
                                                                alt="fuse"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <label>LT fuse</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <hr className="m-0" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <table
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <tr>
                                                                <td
                                                                    valign="top"
                                                                    style={{
                                                                        width: "50%",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="sld/new_image/solar-icon.svg"
                                                                        className="d-block"
                                                                        alt="Solar load"
                                                                    />
                                                                </td>
                                                                <td
                                                                    valign="top"
                                                                    style={{
                                                                        width: "50%",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="sld/new_image/Consumers-icon.svg"
                                                                        className="mx-auto d-block"
                                                                        alt="load"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            {renderSummary()}
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img
                                                src="sld/teletransmitted_switches/isolator_group_b_closed2.svg"
                                                className="mx-auto d-block"
                                                alt=""
                                            />
                                        </a>
                                    </li>
                                </ul>
                            ) : (
                                ""
                            )}

                            {/* RMU SLD */}
                            {selectedFeature?.point_name === "RMU with TC" ||
                                selectedFeature?.point_name === "RMU" ? (
                                <ul
                                    data-parent="rmu-sld-bar"
                                    className={`${opendOption === "sld"
                                        ? "d-block rmu-sld-bar"
                                        : "d-none rmu-sld-bar"
                                        }`}
                                >
                                    <li>
                                        <h6 className="sub-menu-title mb-0">
                                            RMU SLD view
                                        </h6>
                                    </li>
                                    <li>
                                        <table
                                            cellPadding={0}
                                            cellSpacing={0}
                                            className="diagram-tbl-chart w-100 mx-auto mt-3"
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        valign="middle"
                                                        className="text-center"
                                                    >
                                                        A
                                                    </td>
                                                    <td></td>
                                                    <td
                                                        valign="middle"
                                                        className="text-center"
                                                    >
                                                        B
                                                    </td>
                                                    <td></td>
                                                    <td
                                                        valign="middle"
                                                        className="text-center"
                                                    >
                                                        C
                                                    </td>
                                                    <td>
                                                    </td>
                                                    {selectedFeature?.rmu_type === '4 Way' ? <>
                                                        <td
                                                            valign="middle"
                                                            className="text-center"
                                                        >
                                                            D
                                                        </td>
                                                        <td>
                                                        </td>
                                                    </> : ''}
                                                </tr>
                                                <tr>
                                                    <td colSpan={selectedFeature?.rmu_type === '4 Way' ? 8 : 6}>
                                                        <hr className="m-0" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        valign="top"
                                                        className="line-vr"
                                                    ></td>
                                                    <td colSpan={1}></td>
                                                    <td
                                                        valign="top"
                                                        className="line-vr"
                                                    ></td>
                                                    <td colSpan={1}></td>
                                                    {selectedFeature?.rmu_type === '4 Way' ?
                                                        <>
                                                            <td
                                                                valign="top"
                                                                className="line-vr"
                                                            ></td>
                                                            <td colSpan={1}></td>
                                                        </> : ''
                                                    }
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/MFT-1.svg"
                                                            style={{
                                                                "padding-right": "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="LBS"
                                                            title="LBS"
                                                        />
                                                    </td>
                                                    <td colSpan={1}></td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/iso-sm.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="LBS"
                                                            title="LBS"
                                                        />
                                                    </td>
                                                    <td valign="middle">
                                                        <label>LBS</label>
                                                    </td>
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/iso-sm.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="LBS"
                                                            title="LBS"
                                                        />
                                                    </td>
                                                    <td valign="middle">
                                                        <label>LBS</label>
                                                    </td>
                                                    {selectedFeature?.rmu_type === '4 Way' ?
                                                        <>
                                                            <td valign="top">
                                                                <img
                                                                    src="rmu_sld/iso-sm.svg"
                                                                    style={{
                                                                        "padding-right":
                                                                            "0",
                                                                        //width: "100%",
                                                                    }}
                                                                    className="mx-auto d-block"
                                                                    alt="LBS"
                                                                    title="LBS"
                                                                />
                                                            </td>
                                                            <td valign="middle">
                                                                <label>LBS</label>
                                                            </td>
                                                        </> : ''}
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/circuit_braker_sm.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="LBS"
                                                            title="LBS"
                                                        />
                                                    </td>
                                                    <td valign="middle">
                                                        <label>LBS</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/fpi_earth_ren.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="Eath Switch"
                                                            title="Eath Switch"
                                                        />
                                                    </td>
                                                    <td valign="middle">
                                                        <label>ES</label>
                                                    </td>
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/fpi_earth_ren.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="Eath Switch"
                                                            title="Eath Switch"
                                                        />
                                                    </td>
                                                    <td valign="middle">
                                                        <label>ES</label>
                                                    </td>
                                                    {selectedFeature?.rmu_type === '4 Way' ?
                                                        <>
                                                            <td valign="top">
                                                                <img
                                                                    src="rmu_sld/fpi_earth_ren.svg"
                                                                    style={{
                                                                        "padding-right":
                                                                            "0",
                                                                        //width: "100%",
                                                                    }}
                                                                    className="mx-auto d-block"
                                                                    alt="Eath Switch"
                                                                    title="Eath Switch"
                                                                />
                                                            </td>
                                                            <td valign="middle">
                                                                <label>ES</label>
                                                            </td>
                                                        </> : ''}
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/fpi_earth_ren.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="Eath Switch"
                                                            title="Eath Switch"
                                                        />
                                                    </td>
                                                    <td valign="middle">
                                                        <label>ES</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/FPI-11.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="Fault Passage Indicator"
                                                            title="Fault Passage Indicator"
                                                        />
                                                    </td>
                                                    <td>FPI</td>
                                                    <td
                                                        valign="top"
                                                        className="line-vr"
                                                    ></td>
                                                    <td colSpan={1}></td>
                                                    {selectedFeature?.rmu_type === '4 Way' ? <>
                                                        <td
                                                            valign="top"
                                                            className="line-vr"
                                                        ></td>
                                                        <td colSpan={1}></td>
                                                    </> : ''}
                                                    <td
                                                        valign="top"
                                                        className="line-vr"
                                                    ></td>
                                                    <td colSpan={1}></td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/in_dir.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="load in"
                                                            title="load In"
                                                        />
                                                    </td>
                                                    <td valign="middle">
                                                        <label>Load In</label>
                                                    </td>
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/out_dir.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="load out"
                                                            title="load out"
                                                        />
                                                    </td>
                                                    <td valign="middle">
                                                        <label>Load Out</label>
                                                    </td>
                                                    {selectedFeature?.rmu_type === '4 Way' ? <>
                                                        <td valign="top">
                                                            <img
                                                                src="rmu_sld/out_dir.svg"
                                                                style={{
                                                                    "padding-right":
                                                                        "0",
                                                                    //width: "100%",
                                                                }}
                                                                className="mx-auto d-block"
                                                                alt="load out"
                                                                title="load out"
                                                            />
                                                        </td>
                                                        <td valign="middle">
                                                            <label>Load Out</label>
                                                        </td>
                                                    </> : ''}
                                                    <td valign="top">
                                                        <img
                                                            src="rmu_sld/out_dir.svg"
                                                            style={{
                                                                "padding-right":
                                                                    "0",
                                                                //width: "100%",
                                                            }}
                                                            className="mx-auto d-block"
                                                            alt="load out"
                                                            title="load out"
                                                        />
                                                    </td>
                                                    <td valign="middle">
                                                        <label>Load Out</label>
                                                    </td>
                                                </tr>
                                                {selectedFeature?.point_name === "RMU" ? (
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <b>Name:</b> {featureParentChild.parent?.properties.point_type}<br />
                                                            <b>ID:</b>{" "}
                                                            {
                                                                featureParentChild.parent?.properties.id
                                                            }
                                                        </td>
                                                        <td colSpan={2}>
                                                            <b>Name:</b> {featureParentChild.children?.[0].properties.point_type}<br />
                                                            <b>ID:</b>{" "}
                                                            {
                                                                featureParentChild.children?.[0]?.properties.id
                                                            }
                                                        </td>
                                                        {selectedFeature?.rmu_type === '4 Way' ?
                                                            <td colSpan={2}>
                                                                <b>Name:</b> (N/A)<br />
                                                                <b>ID:</b>{" "}
                                                                {
                                                                    '0'
                                                                }
                                                            </td>
                                                            : ''}
                                                        <td colSpan={2}>
                                                            <b>Name:</b> {selectedFeature?.point_name}<br />
                                                            <b>ID:</b>{" "}
                                                            {
                                                                selectedFeature?.element_id
                                                            }
                                                        </td>
                                                    </tr>
                                                ) : ""}
                                                {selectedFeature?.point_name === "RMU with TC" ? (
                                                    <>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <b>Name:</b> {featureParentChild.parent?.properties.point_type}<br />
                                                                <b>ID:</b>{" "}
                                                                {
                                                                    featureParentChild.parent?.properties.id
                                                                }
                                                            </td>
                                                            <td colSpan={2}>
                                                                <b>Name:</b> {featureParentChild.children?.[0].properties.point_type}<br />
                                                                <b>ID:</b>{" "}
                                                                {
                                                                    featureParentChild.children?.[0]?.properties.id
                                                                }
                                                            </td>
                                                            {selectedFeature?.rmu_type === '4 Way' ?
                                                                <td colSpan={2}>
                                                                    <b>Name:</b> (N/A)<br />
                                                                    <b>ID:</b>{" "}
                                                                    {
                                                                        '0'
                                                                    }
                                                                </td>
                                                                : ''}
                                                            <td className="line-vr"></td>
                                                            <td>
                                                                <b>Name:</b> {selectedFeature?.point_name}<br />
                                                                <b>ID:</b>{" "}
                                                                {
                                                                    selectedFeature?.element_id
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={selectedFeature?.rmu_type === '4 Way' ? 6 : 4}></td>
                                                            <td valign="top">
                                                                <img
                                                                    src="sld/new_image/LA-New.svg"
                                                                    style={{
                                                                        width: "100%",
                                                                        "min-width":
                                                                            "70px",
                                                                    }}
                                                                    className="mx-auto d-block"
                                                                    alt="LA"
                                                                    title="LA 230V"
                                                                />
                                                            </td>
                                                            <td valign="middle">
                                                                <label>
                                                                    LA
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={selectedFeature?.rmu_type === '4 Way' ? 6 : 4}></td>
                                                            <td>
                                                                <img
                                                                    src="sld/teletransmitted_switches/fuse_active.svg"
                                                                    className="mx-auto d-block"
                                                                    alt="fuse"
                                                                />
                                                            </td>
                                                            <td valign="middle">
                                                                <label>
                                                                    HT Fuse
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={selectedFeature?.rmu_type === '4 Way' ? 6 : 4}></td>
                                                            <td>
                                                                <img
                                                                    src="sld/new_image/transformer.svg"
                                                                    className="mx-auto d-block"
                                                                    alt="Transformer"
                                                                />
                                                            </td>
                                                            <td>
                                                                <label>
                                                                    <span>
                                                                        Transformer
                                                                    </span>
                                                                    <br />
                                                                    {selectedFeature?.xmr_capacity ??
                                                                        0}{" "}
                                                                    KVA
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={selectedFeature?.rmu_type === '4 Way' ? 6 : 4}></td>
                                                            <td>
                                                                <img
                                                                    src="sld/teletransmitted_switches/Fuse.svg"
                                                                    className="mx-auto d-block"
                                                                    alt="fuse"
                                                                />
                                                            </td>
                                                            <td>
                                                                <label>
                                                                    LT fuse
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={selectedFeature?.rmu_type === '4 Way' ? 6 : 4}></td>
                                                            <td colSpan={2}>
                                                                <hr className="m-0" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={selectedFeature?.rmu_type === '4 Way' ? 6 : 4}></td>
                                                            <td colSpan={2}>
                                                                <table
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <tr>
                                                                        <td
                                                                            valign="top"
                                                                            style={{
                                                                                width: "50%",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="sld/new_image/solar-icon.svg"
                                                                                className="d-block"
                                                                                alt="Solar load"
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            valign="top"
                                                                            style={{
                                                                                width: "50%",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="sld/new_image/Consumers-icon.svg"
                                                                                className="mx-auto d-block"
                                                                                alt="load"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    {renderSummary()}
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : ("")}
                                            </tbody>
                                        </table>
                                    </li>
                                </ul>
                            ) : (
                                ""
                            )}
                            {/* SLD Code: END */}
                        </>
                    ) : (
                        ""
                    )}
                    {/* ***********************************************Properties form when we can doubleclick on image****************************************************************** */}
                    {selectedFeature ? (
                        <>
                            <ul
                                data-parent="properties-bar"
                                className={`${opendOption === "new network properties"
                                    ? "d-block"
                                    : "d-none"
                                    }`}
                            >
                                <li>
                                    <h6 className="sub-menu-title mb-0">
                                        {allFormsCompleted && allFormsCompleted === true ? 'Network Completed' : 'Properties'}
                                    </h6>
                                </li>
                                <>
                                    {opendOption === "new network properties" && selectDoubleImage === "Transformer" ? (
                                        <TransformerAddNetwork
                                            setRightSidebarVisible={setRightSidebarVisible}
                                            selectedMarker={selectedMarker}
                                            onSave={handleNetworkElementSave}
                                            onFormSave={() => handleFormCompletion('transformer', true)}
                                            parentId={parentId}
                                            parentId1={parentId1}
                                            getidInRighSidebar={getidInRighSidebar}
                                        />
                                    ) : selectDoubleImage === "Switch" ? (
                                        <SwitchAddNetwork
                                            setRightSidebarVisible={setRightSidebarVisible}
                                            selectedMarker={selectedMarker}
                                            onSave={handleNetworkElementSave}
                                            onFormSave={() => handleFormCompletion('switch', true)}
                                            parentId={parentId}
                                            parentId1={parentId1}
                                            getidInRighSidebar={getidInRighSidebar}
                                        />
                                    ) : selectDoubleImage === "HT Pole" ? (
                                        <HtPoleAddNetwork
                                            setRightSidebarVisible={setRightSidebarVisible}
                                            selectedMarker={selectedMarker}
                                            onSave={handleNetworkElementSave}
                                            onFormSave={() => handleFormCompletion('htPole', true)}
                                            parentId={parentId}
                                            parentId1={parentId1}
                                            getidInRighSidebar={getidInRighSidebar}
                                        />
                                    ) : selectDoubleImage === "Fuse" ? (
                                        <FuseAddNetwork
                                            setRightSidebarVisible={setRightSidebarVisible}
                                            onFormSave={() => handleFormCompletion('fuseAddNetwork', true)}
                                            selectedMarker={selectedMarker}
                                            onSave={handleNetworkElementSave}
                                            parentId={parentId}
                                            parentId1={parentId1}
                                            getidInRighSidebar={getidInRighSidebar}
                                        />
                                    ) : selectDoubleImage === "CTPT" ? (
                                        <CtptAddNetwork
                                            setRightSidebarVisible={setRightSidebarVisible}
                                            onFormSave={() => handleFormCompletion('ctptForm', true)}
                                            selectedMarker={selectedMarker}
                                            onSave={handleNetworkElementSave}
                                            parentId={parentId}
                                            parentId1={parentId1}
                                            getidInRighSidebar={getidInRighSidebar}
                                        />
                                    ) : selectDoubleImage === "Gentry" ? (
                                        <GentryAddNetwork
                                            setRightSidebarVisible={setRightSidebarVisible}
                                            selectedMarker={selectedMarker}
                                            onSave={handleNetworkElementSave}
                                            onFormSave={() => handleFormCompletion('gentry', true)}
                                            parentId={parentId}
                                            parentId1={parentId1}
                                            getidInRighSidebar={getidInRighSidebar}
                                        />
                                    ) : selectDoubleImage === "HT Route Point" ? (
                                        <HtRoutePoint
                                            setRightSidebarVisible={setRightSidebarVisible}
                                            selectedMarker={selectedMarker}
                                            onSave={handleNetworkElementSave}
                                            onFormSave={() => handleFormCompletion('htRoute', true)}
                                            parentId={parentId}
                                            parentId1={parentId1}
                                            getidInRighSidebar={getidInRighSidebar}
                                        />
                                    ) : selectDoubleImage === "RMU" ? (
                                        <RmuAddNetwork
                                            setRightSidebarVisible={setRightSidebarVisible}
                                            selectedMarker={selectedMarker}
                                            onSave={handleNetworkElementSave}
                                            onFormSave={() => handleFormCompletion('rmu', true)}
                                            parentId={parentId}
                                            parentId1={parentId1}
                                            getidInRighSidebar={getidInRighSidebar}
                                        />
                                    ) : ""}
                                </>
                                <br/>
                                <button disabled={!allFormsCompleted} onClick={handleSubmitNetwork} style={{
                                padding: "8px 30px",
                                backgroundColor: "#427d9d",
                                borderRadius: "8px",
                                color: "white",
                                fontSize: "12px",
                                marginTop: "20px",
                            }}>Submit Network</button>
                            </ul>
                        </>
                    ) : (
                        ""
                    )}
                    {/* ********************************************************************************************************************************** */}
                    <ul
                        data-parent="tracing-bar"
                        className={`${opendOption === "tracing" ? "d-block" : "d-none"
                            }`}
                    >
                        <li>
                            <h6 className="sub-menu-title mb-0">Tracing</h6>
                        </li>
                        <li>
                            <a href="#" onClick={() => handleTracing("down")}>
                                Downward
                            </a>
                        </li>
                        {selectedFeature &&
                            selectedFeature["point_name"] !== "Gentry" ? (
                            <li>
                                <a href="#" onClick={() => handleTracing("up")}>
                                    Upward
                                </a>
                            </li>
                        ) : (
                            ""
                        )}
                        <li>
                            <h6 className="sub-menu-title mb-0">
                                Trace Between
                            </h6>
                        </li>
                        <li>
                            <a href="#" onClick={() => handleTracing("start")}>
                                Start Point
                            </a>
                        </li>
                        <li>
                            <a href="#" onClick={() => handleTracing("end")}>
                                End Point
                            </a>
                        </li>

                        {networkDetails.pathLength &&
                            networkDetails.pathLength > 0 ? (
                            <>
                                <li>
                                    <h6 className="sub-menu-title mb-0">
                                        Network Details
                                    </h6>
                                </li>
                                <li>
                                    <a href="#">
                                        Total length:{" "}
                                        {networkDetails.pathLength ?? 0} km
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Total span:{" "}
                                        {networkDetails.spanCount ?? 0}{" "}
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Total Transformer:{" "}
                                        {networkDetails.Transformer ?? 0}{" "}
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Total Switch:{" "}
                                        {networkDetails.Switch ?? 0}{" "}
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Total XMR Capacity:{" "}
                                        {networkDetails.Capacity ?? 0} KVA{" "}
                                    </a>
                                </li>
                            </>
                        ) : (
                            ""
                        )}
                    </ul>
                    <ul
                        data-parent="add-network-button"
                        className={`${opendOption === "add-network-button"
                            ? "d-block"
                            : "d-none"
                            }`}
                    >
                        <li>
                            <h6 className="sub-menu-title mb-0">
                                Select Element
                            </h6>
                        </li>
                        <li>
                            <div className="element-wrapper">
                                {images?.map((image) => (
                                    <>
                                        <div
                                            className="element-item"
                                            style={{}}
                                        >
                                            <p className="mb-0">{image.name}</p>
                                            <div>
                                                <img
                                                    key={image.id}
                                                    src={image.src}
                                                    className="element-icon mt-1"
                                                    onClick={() =>
                                                        handleImageClick(image)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                            <div>
                                Total Network Length:{totalDistance1.toFixed(2)} mtr
                            </div>
                            <div className="text-center mt-4">
                                <button
                                    className="btn-custom py-2 mt-4"
                                    onClick={clearImageFromMarker}
                                >
                                    Clear Element
                                </button>
                                <button
                                    className="btn-custom py-2 mt-4"
                                    onClick={clearLastLine}
                                >
                                    Clear Line
                                </button>
                                <button
                                    className="btn-custom py-2 mt-4"
                                    onClick={clearNetwork}
                                >
                                    Clear Network
                                </button>
                                <button
                                    className="btn-custom py-2 mt-4"
                                    onClick={addChildNetwork}
                                >
                                    Add Child Network
                                </button>
                            </div>
                        </li>
                    </ul>
                    <ul
                        data-parent="map-tool-bar"
                        className={`${opendOption === "map_tool" ? "d-block" : "d-none"
                            }`}
                    >
                        <li>
                            <h6 className="sub-menu-title mb-0">Map Tool</h6>
                        </li>
                        <li>
                            <a href="#" onClick={() => handleTracing("length")}>
                                Length
                            </a>
                        </li>
                        <li>
                            <a href="#" style={{ color: "#cccecf" }}>
                                Area
                            </a>
                        </li>
                    </ul>
                    <ul
                        data-parent="draw-bar"
                        className={`${opendOption === "draw" ? "d-block" : "d-none"
                            }`}
                        onClick={handleCallApi}
                    >
                        <li>
                            <h6 className="sub-menu-title mb-0">Draw</h6>
                        </li>
                        <li>
                            <a href="#" style={{ color: "#cccecf" }}>
                                Point{" "}
                            </a>
                        </li>
                        <li>
                            <a href="#" style={{ color: "#cccecf" }}>
                                Line{" "}
                            </a>
                        </li>
                        <li>
                            <a href="#" style={{ color: "#cccecf" }}>
                                Polygon{" "}
                            </a>
                        </li>
                    </ul>
                    {isEditing ? (
                        <div>
                            <ul>
                                <li>
                                    <h6 className="sub-menu-title mb-0">
                                        Edit Line Properties
                                    </h6>
                                </li>
                                <form>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label1" htmlFor="Position">Position</label>
                                            <select
                                                className="form-select form-select-sm"
                                                id="Position"
                                                value={formData.position || ""}
                                                onChange={(e) => handleFormDataChange('position', e.target.value)}
                                            >
                                                <option value="">Overhead</option>
                                                <option value="underground">Underground</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label1" htmlFor="type">Type</label>
                                            <select
                                                className="form-select form-select-sm"
                                                id="type"
                                                value={formData.type || "AAAC-Rabbit (55 mm²)"} // Default value
                                                onChange={(e) => handleFormDataChange('type', e.target.value)}
                                            >
                                                <option value="AAAC-Rabbit (55 mm²)">AAAC-Rabbit (55 mm²)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button
                                        className="save-button"
                                        style={{
                                            padding: "8px 30px",
                                            backgroundColor: "#427d9d",
                                            borderRadius: "8px",
                                            color: "white",
                                            fontSize: "12px",
                                            marginTop: "20px",
                                        }}
                                        onClick={handleEditClickSave}
                                        disabled={isSaveButtonDisabled}
                                    >
                                        Save
                                    </button>
                                    <button
                                        style={{
                                            padding: "8px 30px",
                                            backgroundColor: "white",
                                            borderRadius: "8px",
                                            color: "black",
                                            fontSize: "12px",
                                            marginTop: "20px",
                                            marginLeft: "10px",
                                            border: "1px solid black",
                                        }}
                                        onClick={handleCloseClick}
                                    >
                                        Cancel
                                    </button>
                                </form>

                            </ul>
                        </div>
                    ) : (
                        <ul
                            data-parent="line-properties-bar"
                            className={`${opendOption === "line-properties"
                                ? "d-block"
                                : "d-none"
                                }`}
                        >
                            <li>
                                <h6 className="sub-menu-title mb-0">
                                    Line Properties
                                </h6>
                            </li>
                            {lineProps !== null
                                ? Object.keys(lineProps).map((innerAttr, index) => {
                                    if (lineProps[innerAttr] === null)
                                        lineProps[innerAttr] = "N/A"; // Handling null values
                                    return (
                                        <ul
                                            className="sub-menu-child"
                                            key={index}
                                        >
                                            <li className="label-name">
                                                {innerAttr.replace(/_/g, " ")}
                                            </li>
                                            <li>
                                                {printIfBoolean(
                                                    lineProps[innerAttr]
                                                )}
                                            </li>
                                        </ul>
                                    );
                                })
                                : ""}
                            <button type="button" onClick={handleEditClickLine} style={{
                                padding: "8px 30px",
                                backgroundColor: "#427d9d",
                                borderRadius: "8px",
                                color: "white",
                                fontSize: "12px",
                                marginTop: "20px",
                            }}>Edit</button>
                        </ul>
                    )}
                    <ul data-parent="style-bar" className="d-none">
                        <li>
                            <h6 className="sub-menu-title mb-0">Style</h6>
                        </li>
                        <li>
                            <a href="#"></a>
                        </li>
                    </ul>
                    <button className="close-submenu" onClick={removeClass}>
                        <img src="right-double-chevron.png" alt="" />
                    </button>
                </div>
            ) : (
                ""
            )}
            <div className="main-menu">
                <ul className="rightsidebar-menu">
                    {selectedFeature ? (
                        <>
                            <li>
                                <a
                                    onClick={() =>
                                        openRightSubmenu("properties")
                                    }
                                    title="Properties"
                                >
                                    <img
                                        src="Properties.svg"
                                        className="sidebar-icon"
                                        alt="properties"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => {
                                        openRightSubmenu("sld");
                                        // Add your additional onClick functionality here
                                    }}
                                    title="sld view"
                                >
                                    <img
                                        src="sld/transformer_white.svg"
                                        className="sidebar-icon"
                                        alt="SLD"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => openRightSubmenu("tracing")}
                                    title="Tracing"
                                >
                                    <img
                                        src="Tracing.svg"
                                        className="sidebar-icon"
                                        alt="tracing"
                                    />
                                </a>
                            </li>
                        </>
                    ) : (
                        ""
                    )}
                    <li>
                        <a
                            onClick={() => openRightSubmenu("map_tool")}
                            title="Map Tool"
                        >
                            <img
                                src="Map-Tool.svg"
                                className="sidebar-icon"
                                alt="map_tool"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={() => openRightSubmenu("draw")}
                            title="Draw"
                        >
                            <img
                                src="Draw.svg"
                                className="sidebar-icon"
                                alt="draw"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img
                                src="Style.svg"
                                className="sidebar-icon"
                                alt=""
                            />
                            <span>Style</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default RightSidebar;
