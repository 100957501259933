import React, { useState, useRef, useEffect, useCallback } from "react";
import L from "leaflet";
import {
    MapContainer,
    LayersControl,
    TileLayer,
    Marker,
    Polyline,
    GeoJSON,
    Circle,
    useMap,
    Tooltip,
    useMapEvents,
    ZoomControl,
    Popup,
    FeatureGroup,
} from "react-leaflet";
import ReactToPrint from 'react-to-print';
import * as turf from "@turf/turf";
import { BingLayer } from "react-leaflet-bing-v2";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import jsPDF from "jspdf";
import "./css/map_view.css";
import PointData from "../assets/sec_point";
import Linedata from "../assets/sec_line";
import RightSidebar from "./RightSidebar";
import LeftSidebar from "./LeftSidebar";
import TopNavbar from "./TopNavbar";
import { pointsOnLine, convertClassNameString } from "../utils/map";
// Import custom marker icon images
import domtoimage from 'dom-to-image';
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";
import ViewTaskNetwork from "./view-task/ViewTaskNetwork";
import ViewTaskMap from "./view-task/ViewTaskMap";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
const isValidCoordinate = (lat, lng) =>
    !isNaN(lat) &&
    !isNaN(lng) &&
    lat >= -90 &&
    lat <= 90 &&
    lng >= -180 &&
    lng <= 180;

// Define a function to snap coordinates to the nearest valid location
const snapToNearestLocation = (lat, lng) => {
    const snapValue = 0.001;
    const snappedLat = Math.round(lat / snapValue) * snapValue;
    const snappedLng = Math.round(lng / snapValue) * snapValue;
    return [snappedLat, snappedLng];
};

/*import Markers from './VenueMarkers';*/
const MapComponent = React.forwardRef(({ authDetails, isPrinting }, ref) => {
    //state = {};
    const { BaseLayer } = LayersControl;
    const mapRef = useRef(null);
    const geoJSONBetweenTraceRef = useRef(null);
    const [activeLayer, setActiveLayer] = useState("OpenStreetMap");
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [selectedFeatureId, setSelectedFeatureId] = useState([]);
    const [featureProperties, setFeatureProperties] = useState(null);
    const [zoom, setZoom] = useState(13);
    const [datapoint, setDataFromChild] = useState(PointData);
    const [dataLine, setLineDataFromChild] = useState(Linedata);
    const [pointLayer, setPointLayer] = useState(null);
    const [lineLayer, setLineLayer] = useState(null);
    const [center, setCenter] = useState([
        PointData.features[0].geometry.coordinates[1],
        PointData.features[0].geometry.coordinates[0],
    ]);
    const [isEditing, setIsEditing] = useState(false);
    const [dataOnHandleEditClick, setDataOnHandleEditClick] = useState(false);
    const [className, setClassName] = useState("container-hide");
    const [poleClassName, setPoleClassName] = useState(isPrinting ? "pole-show" : "pole-hide");
    const [lineClassName, setLineClassName] = useState("line");
    const [selectedLineId, setSetSelectedLineId] = useState(null);
    const [selectedLinePops, setSelectedLinePops] = useState(null);
    const [pointDataElements, setPointDataElements] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [markersNewData, setMarkersNewData] = useState([0, 0]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [traceBetween, setTraceBetween] = useState([]);
    const [openedRightMenuOption, setOpenedRightMenuOption] = useState(null);
    const [circles, setCircles] = useState([]);
    const [networkDetails, setNNetworkDetails] = useState({
        pathLength: 0,
        spanCount: 0,
        Transformer: 0,
        Switch: 0,
        Capacity: 0,
    });
    const [isMeasuring, setIsMeasuring] = useState(false);
    const [taskNetworkVisible, setTaskNetworkVisible] = useState(false);
    const [taskMapkVisible, setTaskMapVisible] = useState(false);
    const [points, setPoints] = useState([]);
    const [distances, setDistances] = useState([]);
    const [totalDistance, setTotalDistance] = useState(0);
    const [totalDistance1, setTotalDistance1] = useState(0);
    const [getidInRighSidebar, setGetIdInRightsidebar] = useState(null);
    const [cursorPosition, setCursorPosition] = useState(null);
    const [measuringPoint, setMeasuringPoint] = useState(null);
    const [lineColor, setLineColor] = useState("#0080ff");
    const [projectLineColor, setProjectLineColor] = useState([]);
    const [tracingLineColor, setTracingLineColor] = useState("#ff7900");
    const [addNetworkColor, setAddNetworkColor] = useState("#A020F0");
    const [isRightSidebarVisible, setRightSidebarVisible] = useState(true);
    const [blankMapOpen, setblankMapOpen] = useState(false);
    const [polylineSegments, setPolylineSegments] = useState([]);
    const [currentRedCircle, setCurrentRedCircle] = useState(null);
    const currentRedCircleRef = useRef(null);
    const [selectDoubleImage, setSelectDoubleImage] = useState('');
    const [currentLine, setCurrentLine] = React.useState(null);
    const [distanceTooltips, setDistanceTooltips] = useState([]);
    const [existingPoint, setExistingPoint] = useState(null);
    const [isAddingNetwork, setIsAddingNetwork] = useState(false);
    const [distanceTooltip, setDistanceTooltip] = useState(null);
    const [isAddingChildNetwork, setIsAddingChildNetwork] = useState(false);
    const [existingChildPoint, setExistingChildPoint] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [subDivisions, setSubDivisions] = useState([]);
    const [parentID, setParentID] = useState('');
    const [parentID1, setParentID1] = useState('');
    const markersRef = useRef([]);
    const linesRef = useRef([]);
    const currentLineRef = useRef(null);
    const currentDistanceTooltipRef = useRef(null);
    const newlyAddedMarkersRef = React.useRef([]);

    const gujaratBounds = [
        [19.1, 67.0],
        [25.6, 75.5],
    ];

    const [visibleElements, setVisibleElements] = useState({
        line: true,
        gentry: true,
        ht_pole: true,
        transformer: true,
        rmu: true,
        rmu_with_tc: true,
        switch: true,
        fuse: true,
        ctpt: true,
        ht_route_point: true
    });

    const [isDoubleclickIsActive, setDoubleClickisActive] = useState(false);
    const [addNetworkPayload, setAddNetworkPayload] = useState([]);
    const [addNetworkLastPayload, setAddNetworkLastPayload] = useState(null);
    const [finalNetworkPayload, setFinalNetworkPayload] = useState([]);

    const defaultIcon = L.icon({
        iconUrl: L.Icon.Default.prototype.options.iconUrl,
        shadowUrl: L.Icon.Default.prototype.options.shadowUrl,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });
    useEffect(() => {
        // Fetch categories from API
        fetch(process.env.REACT_APP_API_URL + "/api/get-organazations")
            .then((response) => response.json())
            .then((result) => {
                setOrganizations(result.data); // Assuming the API response is an array of category objects
                setDivisions(result.data.divisions);
                setSubDivisions(result.data.subDivisions);
            })
            .catch((error) => {
            });
    }, []);

    useEffect(() => {
        // Fetch categories from API
        if (!isAddingNetwork && !isAddingChildNetwork) {
            console.log(addNetworkPayload);
        }

    }, [isAddingNetwork, isAddingChildNetwork]);

    useEffect(() => {
        const isValidNetwork = checkAllValidAndParent(addNetworkPayload);
        if(isValidNetwork === true){
            setOpenedRightMenuOption({
                tab: "new network properties",
                time: String(new Date())
            });
            setRightSidebarVisible(true);
            setFinalNetworkPayload(addNetworkPayload);

            console.log("new network properties open in rightsidebar");
        }
    }, [addNetworkPayload]);

    React.useEffect(() => {
        if (mapRef.current && isPrinting && lineLayer) {
            const map = mapRef.current;
            const layerBounds = lineLayer.getBounds();
            map.fitBounds(layerBounds);
            //map.setZoom(18); // Set zoom level specifically for printing
        }
    }, [isPrinting]);

    const addChildNetwork = () => {
        if (!selectedMarker) {
            alert('Please select a marker to add a child network.');
            return;
        }
        setExistingChildPoint(selectedMarker); // Set the existing marker as the starting point for the child network
        setIsAddingChildNetwork(true);
        setIsAddingNetwork(false);
    };

    const handleMouseMove = useCallback((e) => {
        if (isAddingNetwork || isAddingChildNetwork) {
            const { lat, lng } = e.latlng;
            if (!mapRef.current) return;

            // Determine the start point based on the mode
            const startPoint = isAddingNetwork ? existingPoint : existingChildPoint;
            if (startPoint) {
                // Remove the previous temporary line if it exists
                if (currentLineRef.current) {
                    mapRef.current.removeLayer(currentLineRef.current);
                    currentLineRef.current = null;
                }

                // Draw a new temporary line from the start point to the cursor
                const tempLine = L.polyline([startPoint.getLatLng(), [lat, lng]], {
                    color: addNetworkColor,
                    weight: 4,
                    dashArray: "5, 10", // Dashed line for visual feedback
                }).addTo(mapRef.current);

                // Update the ref with the new temporary line
                currentLineRef.current = tempLine;

                // Remove the previous distance tooltip if it exists
                if (currentDistanceTooltipRef.current) {
                    mapRef.current.removeLayer(currentDistanceTooltipRef.current);
                    currentDistanceTooltipRef.current = null;
                }

                // Calculate and show the distance tooltip
                const distance = startPoint.getLatLng().distanceTo([lat, lng]);
                const roundedDistance = Math.round(distance);
                const distanceTooltip = L.tooltip({
                    className: 'distance-tooltip',
                    permanent: true,
                    direction: 'top',
                    offset: [0, -10]
                }).setLatLng(
                    L.latLng(
                        (startPoint.getLatLng().lat + lat) / 2,
                        (startPoint.getLatLng().lng + lng) / 2
                    )
                ).setContent(`${roundedDistance} mtr`).addTo(mapRef.current);

                // Store the tooltip reference
                currentDistanceTooltipRef.current = distanceTooltip;
            }
        }
    }, [isAddingNetwork, isAddingChildNetwork, existingPoint, existingChildPoint, addNetworkColor]);

    const handleMapClick = useCallback((e) => {
        if (isAddingNetwork || isAddingChildNetwork) {
            const { lat, lng } = e.latlng;
            if (!mapRef.current) return;

            // Stop tracking mouse movements once a point is clicked
            mapRef.current.off('mousemove', handleMouseMove);

            // Generate a unique ID for the marker
            const uniqueId = `${Date.now()}${Math.random().toString(36).substr(2, 9)}`;
            let lastElementId = null;
            if (addNetworkLastPayload) {
                lastElementId = addNetworkLastPayload.id;
            } else {
                if (isAddingChildNetwork) {
                    lastElementId = existingChildPoint?.options?.id || null;
                } else {
                    const lastElement = addNetworkPayload[addNetworkPayload.length - 1];
                    lastElementId = lastElement ? lastElement.id : selectedFeature?.id || null;
                }
            }

            const eleProp = {
                id: uniqueId,
                type: "Feature",
                geometry: {
                    "type": "Point",
                    "coordinates": [
                        lat,
                        lng
                    ]
                },
                project_id: selectedFeature?.project_id || '',
                properties: {
                    id: uniqueId,
                    parent_id: lastElementId,
                    point_type: null,
                    point_props: '',
                    line_props: '',
                    "data_type": "ht_location"
                }
            };

            // Create a new marker at the clicked location with the unique ID
            const newMarker = L.marker([lat, lng], {
                icon: defaultIcon,
                id: uniqueId, // Store the unique ID in the marker's options
                eleProp: eleProp,

            }).addTo(mapRef.current);
            newlyAddedMarkersRef.current = [...newlyAddedMarkersRef.current, newMarker];
            markersRef.current = [...markersRef.current, newMarker];

            // Update the state with the new object
            setAddNetworkPayload([...addNetworkPayload, eleProp]);
            setAddNetworkLastPayload(eleProp);
            //console.log(addNetworkPayload);

            if (isAddingNetwork) {
                if (existingPoint) {
                    // Create a permanent polyline and add it to the map
                    const line = L.polyline([existingPoint.getLatLng(), [lat, lng]], { color: addNetworkColor, weight: 4 }).addTo(mapRef.current);
                    linesRef.current.push(line);

                    // Calculate and display distance
                    addDistanceLabel(existingPoint, newMarker);

                    // Update total distance
                    const distance = existingPoint.getLatLng().distanceTo([lat, lng]);
                    setTotalDistance1(prevTotal => prevTotal + distance);

                    // Set the existing point to the new marker
                    setExistingPoint(newMarker);
                }
            } else if (isAddingChildNetwork) {
                if (existingChildPoint) {
                    // Create a permanent polyline and add it to the map
                    const line = L.polyline([existingChildPoint.getLatLng(), [lat, lng]], { color: addNetworkColor, weight: 4 }).addTo(mapRef.current);
                    linesRef.current.push(line);

                    // Calculate and display distance
                    addDistanceLabel(existingChildPoint, newMarker);

                    // Update existingChildPoint to the new marker
                    setExistingChildPoint(newMarker);
                } else {
                    // If there's no existingChildPoint, set the current marker as the existingChildPoint
                    setExistingChildPoint(newMarker);
                }
            }
        }
    }, [isAddingNetwork, isAddingChildNetwork, existingPoint, existingChildPoint, handleMouseMove, addNetworkColor]);

    const handleMapDoubleClick = useCallback(() => {
        if (currentLineRef.current) {
            mapRef.current.removeLayer(currentLineRef.current);
            currentLineRef.current = null; // Clear the reference
        }
        if (currentDistanceTooltipRef.current) {
            mapRef.current.removeLayer(currentDistanceTooltipRef.current);
            currentDistanceTooltipRef.current = null;
        }
        // Get the last marker
        const newlyAddedLastMarker = newlyAddedMarkersRef.current[newlyAddedMarkersRef.current.length - 1];
        if (newlyAddedLastMarker && newlyAddedLastMarker.remove) {
            newlyAddedLastMarker.remove();
        }
        const lastMarker = markersRef.current[markersRef.current.length - 1];
        if (lastMarker && lastMarker.remove) {
            lastMarker.remove();
        }

        newlyAddedMarkersRef.current = newlyAddedMarkersRef.current.slice(0, -1);
        markersRef.current = markersRef.current.slice(0, -1);
        setAddNetworkPayload(prevState => prevState.slice(0, prevState.length - 1));
        setIsAddingNetwork(false);
        setIsAddingChildNetwork(false); // Exit child network mode
        setExistingChildPoint(null); // Reset the existing child point
        setAddNetworkLastPayload(null);
    }, []);

    useEffect(() => {
        newlyAddedMarkersRef.current.forEach(marker => {
            marker.on('click', () => {
                setSelectedMarker(marker);
                setRightSidebarVisible(true);
                setOpenedRightMenuOption({
                    tab: "add-network-button" || "add-plan-button",
                    time: String(new Date()),
                });
                updateRedCircle(marker);
                console.log(marker.options);
                // Retrieve and log the parent marker ID
                const parentId = marker.options.parentId;
                const parentId1 = marker.options.id;
                if (parentId && parentId1) {
                    setParentID(parentId)
                    setParentID1(parentId1)
                } else {
                    setParentID(getidInRighSidebar.properties.parent_id)
                    setParentID1(parentId1)
                }
            });

            marker.on('dblclick', () => {
                if (marker === selectedMarker) {
                    setDoubleClickisActive(true);
                    setOpenedRightMenuOption({
                        tab: "new network properties",
                        time: String(new Date()),
                    });
                    setDataOnHandleEditClick(false);
                    setIsEditing(false);
                    setSelectDoubleImage(marker.options.imageName);
                }
            });
        });

        return () => {
            newlyAddedMarkersRef.current.forEach(marker => {
                marker.off('click');
                marker.off('dblclick');
            });
        };
    }, [newlyAddedMarkersRef.current, selectedMarker]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.on('mousemove', handleMouseMove);
            mapRef.current.on('click', handleMapClick);
            mapRef.current.on('dblclick', handleMapDoubleClick); // Add the double-click listener

            return () => {
                mapRef.current.off('mousemove', handleMouseMove);
                mapRef.current.off('click', handleMapClick);
                mapRef.current.off('dblclick', handleMapDoubleClick); // Remove the double-click listener
            };
        }
    }, [handleMouseMove, handleMapClick, handleMapDoubleClick]);

    const handleImageSelect = (image, marker) => {
        if (marker) {
            // Remove the previous red circle if it exists
            if (currentRedCircle) {
                mapRef.current.removeLayer(currentRedCircle);
                setCurrentRedCircle(null);
            }

            // Update the icon for the selected marker
            marker.setIcon(
                L.divIcon({
                    html: `<img src="${image.src}" alt="Selected Marker" style="width: 20px; height: 20px;" />`,
                    iconSize: [20, 20],
                    className: "custom-marker",
                })
            );

            const item = marker.options.eleProp;
            item.properties.point_type = image.name;
            marker.options.imageName = image.name;

            updateNetworkById(marker.options.id, item);

            // Add a click event listener to the marker if not already added
            marker.off('dblclick').on('dblclick', () => {
                setDoubleClickisActive(true);
                setOpenedRightMenuOption({
                    tab: "new network properties",
                    time: String(new Date()),
                });
                setDataOnHandleEditClick(false);
                setIsEditing(false);
                setSelectDoubleImage(marker?.options?.imageName);
            });
        }
    };

    // Function to update a specific key for an object by id
    // Function to update object by id
    const updateNetworkById = (id, updatedData) => {
        console.log("Network Updated");
        setAddNetworkPayload(prevState =>
            prevState.map(item =>
                item.id === id ? { ...item, ...updatedData } : item
            )
        );
    };

    const checkAllValidAndParent = (data) => {
        // Check if all elements have isValid true
        const allValid = data.every((item) => item.isValid === true);
    
        // Check if every child (except the first one) has a valid parent_id
        const allParentsValid = data.slice(1).every((item) =>
          data.some((parentItem) => parentItem.id === item.properties.parent_id)
        );
    
        return allValid && allParentsValid;
    };
    

    const clearImageFromMarker = () => {
        if (selectedMarker) {
            // Reset the marker icon to the default icon
            selectedMarker.setIcon(defaultIcon);

            // Remove the image information from the marker's options
            delete selectedMarker.options.imageName;

            // Optionally, you can reset the red circle if needed
            if (currentRedCircleRef.current) {
                mapRef.current.removeLayer(currentRedCircleRef.current);
                setCurrentRedCircle(null);
            }

            // Hide the right sidebar if needed
            setRightSidebarVisible(false);
            setSelectedMarker(null);
        }
    };

    const updateRedCircle = (marker) => {
        // Remove the previous red circle if it exists
        if (currentRedCircleRef.current) {
            mapRef.current.removeLayer(currentRedCircleRef.current);
            currentRedCircleRef.current = null; // Clear the reference
        }

        // Add a new red circle around the selected marker
        const redCircle = L.circle(marker.getLatLng(), {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.2,
            radius: 10
        }).addTo(mapRef.current);

        // Update the ref with the new red circle
        currentRedCircleRef.current = redCircle;
    };

    const clearLastLine = () => {
        if (linesRef.current.length > 0 && markersRef.current.length > 0) {
            // Remove the last line
            const lastLine = linesRef.current.pop();
            mapRef.current.removeLayer(lastLine);

            // Remove the last marker
            const lastMarker = markersRef.current.pop();
            mapRef.current.removeLayer(lastMarker);
        }

        // Remove the red circle if it exists
        if (currentRedCircleRef.current) {
            mapRef.current.removeLayer(currentRedCircleRef.current);
            currentRedCircleRef.current = null; // Clear the reference
        }

        // Remove the red border from the selected marker if it exists
        if (selectedMarker) {
            selectedMarker.setIcon(defaultIcon);
            setSelectedMarker(null);
        }
        distanceTooltips.forEach(tooltip => {
            mapRef.current.removeLayer(tooltip);
        });
        setDistanceTooltips([]);
    };

    const addDistanceLabel = (marker1, marker2) => {
        const latLng1 = marker1.getLatLng();
        const latLng2 = marker2.getLatLng();

        // Calculate the distance in meters
        const distance = latLng1.distanceTo(latLng2);

        // Only create and show the tooltip if the distance is greater than 0
        if (distance > 0) {
            // Round the distance to the nearest integer
            const roundedDistance = Math.round(distance);

            // Create a tooltip with the rounded distance
            const distanceTooltip = L.tooltip({
                className: 'distance-tooltip',
                permanent: true,
                direction: 'top', // Tooltip position
                offset: [0, -10] // Position adjustment
            }).setLatLng(
                L.latLng(
                    (latLng1.lat + latLng2.lat) / 2,
                    (latLng1.lng + latLng2.lng) / 2
                )
            ).setContent(`${roundedDistance} mtr`).addTo(mapRef.current);

            // Store the tooltip reference
            setDistanceTooltips((prevTooltips) => [...prevTooltips, distanceTooltip]);

            return distanceTooltip;
        }
        return null;
    };

    const clearNetwork = () => {
        // Remove all lines
        linesRef.current.forEach(line => {
            mapRef.current.removeLayer(line);
        });
        linesRef.current = [];

        // Remove all markers
        markersRef.current.forEach(marker => {
            mapRef.current.removeLayer(marker);
        });
        markersRef.current = [];
        newlyAddedMarkersRef.current = [];

        // Remove all distance tooltips
        distanceTooltips.forEach(tooltip => {
            mapRef.current.removeLayer(tooltip);
        });
        setDistanceTooltips([]);
        setAddNetworkPayload([]);

        // Remove the red circle
        if (currentRedCircleRef.current) {
            mapRef.current.removeLayer(currentRedCircleRef.current);
            currentRedCircleRef.current = null;
        }

        // Remove any custom images from markers and reset selected marker
        if (selectedMarker) {
            selectedMarker.setIcon(defaultIcon);
            setSelectedMarker(null);
        }

        // Optionally, reset state related to adding network
        setExistingPoint(null);
        setCurrentLine(null);
        setIsAddingNetwork(false);
        setDoubleClickisActive(false);
        setRightSidebarVisible(false);
        setOpenedRightMenuOption(null);
        setSelectDoubleImage(null);
        setDataOnHandleEditClick(null);
        setIsEditing(false);
        setDoubleClickisActive(false);
        setAddNetworkPayload([]);
        setAddNetworkLastPayload(null);
        setFinalNetworkPayload([]);
    };

    const handleClickCancel = () => {
        setRightSidebarVisible(false);
    }

    useEffect(() => {
        if (selectedFeature) {
            const coordinates = selectedFeature.geometry.coordinates;
            const lat = parseFloat(coordinates[1]);
            const lng = parseFloat(coordinates[0]);

            if (isValidCoordinate(lat, lng)) {
            } else {
            }
        }
    }, [selectedFeature]);

    const newCalculateDistance = (latlng1, latlng2) => {
        const from = L.latLng(latlng1);
        const to = L.latLng(latlng2);
        return from.distanceTo(to) / 1000;
    };

    const images = [
        { id: 1, src: "map_icons/Fuse.svg", name: "Fuse" },
        { id: 2, src: "map_icons/ht_pole.svg", name: "HT Pole" },
        { id: 4, src: "map_icons/ht_route_point.svg", name: "HT Route Point" },
        { id: 5, src: "map_icons/Switch.svg", name: "Switch" },
        { id: 3, src: "map_icons/CTPT.svg", name: "CTPT" },
        { id: 6, src: "map_icons/Gentry.svg", name: "Gentry" },
        {
            id: 7,
            src: "map_icons/Transformer.svg",
            name: "Transformer",
        },
        {
            id: 8,
            src: "map_icons/rmu_with_tc.svg",
            name: "RMU with TC",
        },
        { id: 9, src: "map_icons/RMU.svg", name: "RMU" },
        // Add more images as needed
    ];

    const MyComponent = () => {
        useMapEvents({
            mousemove: (e) => {
                setCursorPosition(e.latlng);
            },
            dblclick: (e) => {
                handleDoubleClick(e);
            },
            click: (e) => {
                const newPoints = [...points, e.latlng];
                setPoints(newPoints);
                setMeasuringPoint(e.latlng);
                if (newPoints.length > 1) {
                    const lastPoint = newPoints[newPoints.length - 2];
                    const newDistance = newCalculateDistance(
                        lastPoint,
                        e.latlng
                    );
                    const newTotalDistance = totalDistance + newDistance;

                    setDistances([...distances, newDistance]);
                    setTotalDistance(newTotalDistance);
                }
            },
        });

        return null;
    };

    const handleDoubleClick = (e) => {
        setIsMeasuring(false);
        if (points.length > 1) {
            const newPoints = [...points, e.latlng];
            setPoints(newPoints);
            const lastPoint = newPoints[newPoints.length - 2];
            const newDistance = newCalculateDistance(lastPoint, e.latlng);
            const newTotalDistance = totalDistance + newDistance;

            setDistances([...distances, newDistance]);
            setTotalDistance(newTotalDistance);
        }
        // Open sidebar to select an image after double-click
        setRightSidebarVisible(true);
        // Reset polyline segments after drawing
        setPolylineSegments([]);
    };

    const clearLengthMapping = () => {
        setCursorPosition(null); // Clear cursor position when turning off measuring
        setPoints([]);
        setDistances([]);
        setTotalDistance(0);
    };

    // Create custom icon instance
    const customIcon = new L.Icon({
        iconUrl: markerIcon,
        shadowUrl: markerIconShadow,
        iconSize: [25, 41], // Size of the icon
        iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
        shadowSize: [41, 41], // Size of the shadow
        shadowAnchor: [12, 41], // Point of the shadow which will correspond to marker's location
        popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
    });

    const handleDataFromChild = (dataPoint, dataline, projects) => {
        setDataFromChild(dataPoint);
        setLineDataFromChild(dataline);
        setProjectLineColor(projects);

        const map = mapRef.current;
        const geoJsonLayer = L.geoJSON(dataline);
        const bounds = geoJsonLayer.getBounds();
        map.fitBounds(bounds);

        //setCenter([dataPoint.features[0].geometry.coordinates[1],dataPoint.features[0].geometry.coordinates[0]]);
    };

    useEffect(() => {
        // After setting up or whenever projectLineColor changes, apply new styles
        if (lineLayer) {
            lineLayer.eachLayer(layer => {
                const feature = layer.feature;
                if (feature && feature.project_id) {
                    const result = projectLineColor.find(item => item.project_id === feature.project_id);
                    layer.setStyle({ color: result.color });
                }
            });
        }
    }, [projectLineColor]);

    useEffect(() => {
        // Update map center when center state changes
        if (mapRef.current) {
            mapRef.current.setView(center);
        }
    }, [center]);

    useEffect(() => {
        if (isMeasuring) {
            setMeasuringPoint(
                L.latLng(
                    selectedFeature.geometry.coordinates[1],
                    selectedFeature.geometry.coordinates[0]
                )
            );
        }
    }, [selectedFeature]);

    useEffect(() => {
        Object.keys(visibleElements).map((key) => {
            const classNamePrefix = key === lineClassName ? 'leaflet-interactive' : 'leaflet-marker-icon';
            const elements = document.getElementsByClassName(`${classNamePrefix} ${key}`);

            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.toggle('d-none', !visibleElements[key]);
            }
        });
    }, [visibleElements]);

    useEffect(() => {
        // Update map center when center state changes
        const anchor = document.querySelector(".leaflet-popup-close-button");
        if (anchor) {
            anchor.click();
        }

        if (traceBetween.length > 1) {
            var features = traceBetween;
            const point1 = traceToTopParent(features["0"].properties.id);
            const point2 = traceToTopParent(features["1"].properties.id);
            getNonCommonElements(point1, point2);
        }
    }, [traceBetween]);

    const getNonCommonElements = (json1, json2) => {
        var result = [];
        json1.map((item) => {
            var id = item.id;
            if (json2.some((item) => item.id === id)) {
                //result.push(item);
            } else {
                result.push(item);
            }
        });

        json2.map((item) => {
            var id = item.id;
            if (json1.some((item) => item.id === id)) {
                //result.push(item);
            } else {
                result.push(item);
            }
        });

        setSelectedFeatureId(result);
        const map = mapRef.current;
        geoJSONBetweenTraceRef.current = L.geoJSON(result, {
            onEachFeature: (feature, layer) => onFeatureEachData(feature, layer, true)
        }).addTo(map);

        calculateNetworkDetails(result);

        traceBetween.map((tracePoint, idx) => {
            highlightelement(
                [
                    tracePoint.geometry.coordinates["1"],
                    tracePoint.geometry.coordinates["0"],
                ],
                idx
            );
        });
        setTraceBetween([]);
        //return result;
    };

    const calculateCenter = (coords) => {
        if (coords.length === 0) return [0, 0];

        let totalLat = 0;
        let totalLng = 0;
        coords.forEach((coord) => {
            totalLat += parseFloat(coord[1]);
            totalLng += parseFloat(coord[0]);
        });

        const avgLat = totalLat / coords.length;
        const avgLng = totalLng / coords.length;

        setCenter([avgLat, avgLng]);
        return [avgLat, avgLng];
    };

    const clearLoadedMap = () => {
        if (mapRef.current && pointLayer && lineLayer) {
            setMarkers([]);
            setDataFromChild(null);
            setLineDataFromChild(null);
            setCircles([]);
            setSelectedFeatureId([]);
            pointLayer.clearLayers();
            lineLayer.clearLayers();
        }
        const map = mapRef.current;
        map.eachLayer((layer) => {
            if (!(layer instanceof L.TileLayer)) {
                map.removeLayer(layer);
            }
        });
    };

    useEffect(() => {
        // Clear previous GeoJSON layers
        if (mapRef.current && pointLayer && lineLayer) {
            pointLayer.clearLayers();
            lineLayer.clearLayers();
        }

        // Add new GeoJSON layers
        if (mapRef.current) {
            const map = mapRef.current;

            setPointDataElements(datapoint);

            // Add GeoJSON point layer
            const pointLayer = L.geoJSON(datapoint, {
                onEachFeature: onEachData,
            }).addTo(map);
            setPointLayer(pointLayer);

            // Add GeoJSON line layer
            const lineLayer = L.geoJSON(dataLine, {
                onEachFeature: (feature, layer) => onFeatureEachData(feature, layer, false)
            }).addTo(map);

            setLineLayer(lineLayer);

            map.on("zoomend", function () {
                if (map.getZoom() > 15) {
                    setClassName("container-show");
                } else {
                    setClassName("container-hide");
                }
                if (map.getZoom() > 17) {
                    setPoleClassName("pole-show");
                } else {
                    setPoleClassName("pole-hide");
                }
            });
            Object.keys(visibleElements).map((key) => {
                const classNamePrefix = key === lineClassName ? 'leaflet-interactive' : 'leaflet-marker-icon';
                const elements = document.getElementsByClassName(`${classNamePrefix} ${key}`);

                for (let i = 0; i < elements.length; i++) {
                    elements[i].classList.toggle('d-none', !visibleElements[key]);
                }
            });

            const tracing = L.geoJSON(selectedFeatureId, {
                onEachFeature: (feature, layer) => onFeatureEachData(feature, layer, true)
            }).addTo(map);
        }
    }, [
        datapoint,
        dataLine,
        selectedFeatureId,
        className,
        poleClassName,
        lineColor,
        selectedLineId,
    ]);

    var result = [];
    const buildNestedStructure = (items, parentId) => {
        items.features.map((item) => {
            if (item.properties.parent_id === parentId) {
                const children = buildNestedStructure(items, item.id);
                if (children.length) {
                    item.children = children;
                }
                item.properties.color = "green";
                result.push(item);
            }
        });

        return result;
    };

    // Function to get child and parent for a given id
    const getParentChildRelatedFeatures = (id) => {
        // Finding the item with the given ID
        const currentItem = datapoint.features.find(item => item.id === id);
        if (!currentItem) return { parent: null, children: [] };

        // Parsing point_props JSON string
        //const currentProps = JSON.parse(currentItem.properties.point_props);

        // Finding the parent
        const parent = datapoint.features.find(item => item.id === currentItem.properties.parent_id);

        // Finding children
        const children = datapoint.features.filter(item => item.properties.parent_id === currentItem.id);

        return { parent, children };
    };

    const traceToTopParent = (id, path = []) => {
        if (!id || !dataLine) {
            return path;
        }
        const feature = dataLine.features.find(
            (feature) => feature.properties.id === id
        );
        if (feature && feature.id) {
            feature.properties.color = "green";
            path.push(feature); // Add current feature to the path

            if (feature?.properties?.parent_id) {
                return traceToTopParent(feature.properties.parent_id, path); // Recursively trace to the parent
            }
        }
        return path;
    };

    const handleUpwardTrace = (featureId) => {
        const nestedData = traceToTopParent(featureId);
        setSelectedFeatureId(nestedData);

        const map = mapRef.current;
        const tracing = L.geoJSON(nestedData, {
            onEachFeature: (feature, layer) => onFeatureEachData(feature, layer, true)
        }).addTo(map);
        calculateNetworkDetails(nestedData);
    };

    const calculatePathLength = (line) => {
        try {
            const lineString = turf.lineString(line.geometry.coordinates);
            const length = turf.length(lineString, { units: "kilometers" });
            return length;
        } catch (error) {
            return 0;
        }
    };

    const handleDownTracing = (featureId) => {
        const nestedData = buildNestedStructure(dataLine, featureId);
        const selectedFeature = dataLine.features.find(
            (feature) => feature.properties.parent_id === featureId
        );

        if (selectedFeature) {
            setSelectedFeatureId(nestedData);
            const map = mapRef.current;
            const tracing = L.geoJSON(nestedData, {
                onEachFeature: (feature, layer) => onFeatureEachData(feature, layer, true)
            }).addTo(map);
            calculateNetworkDetails(nestedData);
        }
    }

    const handleFeatureClick = (feature) => {
        let latLngArray = feature.geometry.coordinates;
        let totalDistance = 0;
        for (let i = 0; i < latLngArray.length - 1; i++) {
            const startPoint = L.latLng(latLngArray[i]);
            const endPoint = L.latLng(latLngArray[i + 1]);

            totalDistance += startPoint.distanceTo(endPoint);
        }
        let lineProps = JSON.parse(feature.line_props);
        lineProps.line_length = totalDistance.toFixed(2) + ' meter';
        setSetSelectedLineId(feature.properties.id);
        setSelectedLinePops(lineProps);
        setOpenedRightMenuOption({
            tab: "line-properties",
            time: String(new Date()),
        });
    };

    const onEachData = (data, layer) => {
        setGetIdInRightsidebar(data);
        let dataid = JSON.parse(data.properties.point_props); // data.properties.point_type +' - '+ data.id;
        const dataName = data.properties.point_type;

        let imgURL = "map_icons/ht_pole.svg"; // Default icon URL for HT pole
        switch (dataName) {
            case "Existing Pole(Double Circuit)":
                imgURL = "map_icons/existing_pole.svg";
                break;
            case "Fuse":
                imgURL = "map_icons/Fuse.svg";
                break;
            case "CTPT":
                imgURL = "map_icons/CTPT.svg";
                break;
            case "HT Pole":
                imgURL = "map_icons/ht_pole.svg";
                break;
            case "Switch":
                imgURL = "map_icons/Switch.svg";
                break;
            case "Gentry":
                imgURL = "map_icons/Gentry.svg";
                break;
            case "Transformer":
                imgURL = "map_icons/Transformer.svg";
                break;
            case "HT Route Point":
                imgURL = "map_icons/ht_route_point.svg";
                break;
            case "RMU":
                imgURL = "map_icons/RMU.svg";
                break;
            case "RMU with TC":
                imgURL = "map_icons/rmu_with_tc.svg";
                break;
        }

        const iconSize =
            dataName === "HT Pole" ||
                dataName === "Existing Pole(Double Circuit)" ||
                dataName === "HT Route Point"
                ? [10, 10]
                : [20, 20];

        const classText =
            dataName === "HT Pole" ||
                dataName === "Existing Pole(Double Circuit)" ||
                dataName === "HT Route Point"
                ? poleClassName
                : className;

        var tempName = classText + " " + convertClassNameString(dataName);

        // if (!visibleElements[convertClassNameString(dataName)]) {
        //   tempName = tempName + " d-none";
        // }

        const icon = L.icon({
            iconUrl: imgURL,
            iconSize: iconSize,
            className: tempName,
        });

        layer.setIcon(icon);

        layer
            .bindPopup(
                `
        <div class="leaflet-header">
            <h4>${dataName}</h4>
        </div>
        <div class="leaflet-body">
            <div class="row g-2">
                <div class="col-4">
                    <div class="modal-thumb">
                        <img src="${imgURL}" title="${dataName}" alt="${dataName}">
                    </div>
                </div>
                <div class="col-8">
                    <div class="info-wrap position-relative w-100 pe-4">
                        <h6>Basic Details</h6>
                        <p class=""><span class="text-secondary">Feeder Name: </span><span class="word-break-all">${data.properties.project_name
                }</span></p>
                        <p class=""><span class="text-secondary">Element ID: </span><span class="word-break-all">${data.properties.id
                }</span></p>
                        <div class="dropdown d-inline-block position-absolute top-0 end-0 ms-2">
                            <a href="javascript:void(0);" class="btn-action" title="actions" data-bs-toggle="dropdown" aria-expanded="false"><img src="dots.png" alt="actions"></a>
                            <ul class="dropdown-menu action-dropdown p-2">
                                <li><button class="" id="properties" type="button">Properties</button></li>
                                <li><button class="" id="tracing" type="button">Tracing</button></li>
                                ${dataName === "Gentry"
                    ? '<li><button class="" id="network-check-button" type="button">Check Network</button></li>'
                    : ""
                }
                                ${dataName === "Transformer" ||
                    dataName === "RMU with TC" ||
                    dataName === "RMU"
                    ? '<li><button class="" id="view-sld" type="button">View SLD</button></li>'
                    : ""
                }
                                <li><button class="d-none" id="upward-tracing-button" type="button">Upward Tracing</button></li>
                                <li><button class="d-none" id="downward-tracing-button" type="button">Downward Tracing</button></li>
                                <li><button class="d-none" id="between-tracing-button" type="button">Between Tracing</button></li>
                                <li><button class="" id="add-network-button" type="button">Add Network</button></li>
                                <li><button class="" id="add-plan-button" type="button">Plan Network</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      `
            )
            .on("popupopen", () => setupPopupEventHandlers(data, layer))
            .on("popupclose", () => closePopupEventHandlers(data));

        layer.bindTooltip("Id: " + data.properties.id); // Adding tooltip
    };

    const closePopupEventHandlers = (data) => {
        //setOpenedRightMenuOption(null);
    };

    const calculateNetworkDetails = (pathData) => {
        var length = 0;
        pathData.map((tracePoint, idx) => {
            length = length + calculatePathLength(tracePoint);
        });

        var points = pointsOnLine(pathData, datapoint);
        var transformers = 0;
        var switchCount = 0;
        var totalXmrCapacity = 0;
        var Ids = [];
        if (points?.features && points.features.length) {
            points.features.map((point, idx) => {
                if (point.properties && !Ids.includes(point.properties.id)) {
                    Ids.push(point.properties.id); // Add ID to array if it's not already included
                    if (point.properties.point_type === "Transformer")
                        transformers++;
                    if (point.properties.point_type === "Switch") switchCount++;
                    let point_props = JSON.parse(point.properties.point_props);
                    totalXmrCapacity += Number(point_props.xmr_capacity ?? 0);
                }
            });
        }

        setNNetworkDetails({
            pathLength: length.toFixed(2),
            spanCount: pathData.length,
            Transformer: transformers,
            Switch: switchCount,
            Capacity: totalXmrCapacity,
        });
    };

    const setupPopupEventHandlers = (data, layer) => {
        try {
            const lat = data.geometry.coordinates[1];
            const lng = data.geometry.coordinates[0];
            setGetIdInRightsidebar(data);
            if (isValidCoordinate(lat) && isValidCoordinate(lng)) {
                const [snappedLat, snappedLng] = snapToNearestLocation(
                    lat,
                    lng
                );
                setMarkersNewData([snappedLat, snappedLng]);
            } else {
            }
        } catch (error) { }
        setSelectedFeature(data);
        var featureId = data.properties.id;
        let result = JSON.parse(data.properties.point_props);
        result.point_name = data.properties.point_type;
        result.element_id = data.properties.id;
        setFeatureProperties(result);

        const viewSld = document.querySelector("#view-sld");
        if (viewSld) {
            viewSld.onclick = () => {
                setOpenedRightMenuOption({
                    tab: "sld",
                    time: String(new Date()),
                });
                setRightSidebarVisible(true);
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }

        const downwardButton = document.querySelector(
            "#downward-tracing-button"
        );
        if (downwardButton) {
            downwardButton.onclick = () => {
                //handleFeatureClick(featureId);
                handleDownTracing(featureId);
                setRightSidebarVisible(true);
                // Close the popup
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }

        const upWardButton = document.querySelector("#upward-tracing-button");
        if (upWardButton) {
            upWardButton.onclick = () => {
                handleUpwardTrace(featureId);
                setRightSidebarVisible(true);
                // Close the popup
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }

        const networkCheck = document.querySelector("#network-check-button");
        if (networkCheck) {
            networkCheck.onclick = () => {
                checkNetwork(data);
                setRightSidebarVisible(true);
                // Close the popup
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }

        const betweenButton = document.querySelector("#between-tracing-button");
        if (betweenButton) {
            betweenButton.onclick = () => {
                if (traceBetween.length === null) {
                    setTraceBetween(data);
                } else {
                    setTraceBetween((prevArray) => [...prevArray, data]);
                }
                setRightSidebarVisible(true);
                //const nestedData = traceToTopParent(featureId);
                // Close the popup
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }

        const propertiesBtn = document.querySelector("#properties");
        if (propertiesBtn) {
            propertiesBtn.onclick = () => {
                setOpenedRightMenuOption({
                    tab: "properties",
                    time: String(new Date()),
                });
                setRightSidebarVisible(true);
                // Close the popup
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }
        // const propertiesBtnNew = document.querySelector("#properties new");
        // if (propertiesBtnNew) {
        //     propertiesBtnNew.onclick = () => {
        //         setOpenedRightMenuOption({
        //             tab: "new network properties",
        //             time: String(new Date()),
        //         });
        //         setRightSidebarVisible(true);
        //         // Close the popup
        //         if (mapRef.current) {
        //             mapRef.current.closePopup();
        //         }
        //     };
        // }
        const tracingBtn = document.querySelector("#tracing");
        if (tracingBtn) {
            tracingBtn.onclick = () => {
                setOpenedRightMenuOption({
                    tab: "tracing",
                    time: String(new Date()),
                });
                setRightSidebarVisible(true);

                // Close the popup
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }

        const elementBtn = document.querySelector("#elementButton");
        if (elementBtn) {
            elementBtn.onclick = () => {
                setOpenedRightMenuOption({
                    tab: "elementButton",
                    time: String(new Date()),
                });
                setRightSidebarVisible(true);
                if (!selectedImage) {
                    setSelectedImage(null);
                }

                // Close the popup
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }

        const addNetworkButton = document.querySelector("#add-network-button");
        if (addNetworkButton) {
            addNetworkButton.onclick = () => {
                setIsEditing(false)
                setIsAddingNetwork(true);
                setExistingPoint(layer);
                setAddNetworkColor('#ffdb58');
                if (!selectedImage) {
                    setSelectedImage(null);
                }
                // Close the popup
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }
        const addPlanButton = document.querySelector("#add-plan-button");
        if (addPlanButton) {
            addPlanButton.onclick = () => {
                setIsAddingNetwork(true);
                setDistanceTooltip(true)
                setIsEditing(false)
                setExistingPoint(layer);
                setAddNetworkColor('#A020F0');
                if (!selectedImage) {
                    setSelectedImage(null);
                }
                // Close the popup
                if (mapRef.current) {
                    mapRef.current.closePopup();
                }
            };
        }
        highlightelement([
            data.geometry.coordinates["1"],
            data.geometry.coordinates["0"],
        ]);

        if (geoJSONBetweenTraceRef.current !== null) {
            mapRef.current.removeLayer(geoJSONBetweenTraceRef.current);
            geoJSONBetweenTraceRef.current = null;
        }
    };

    const checkNetwork = (pointData) => {
        setLineColor("#000000");
        setTimeout(
            function () {
                setLineColor("#0080ff");
                alert("Network Connectivity OK!");
            }.bind(this),
            500
        );
    };

    const highlightelement = (latLag, isAdditional = 0) => {
        const newCircle = {
            latlng: latLag,
            radius: 10, // Set the desired radius here
            color: "red",
            fillColor: "#f03",
            fillOpacity: 0,
        };

        if (isAdditional > 0) {
            setCircles((prevCircles) => [...prevCircles, newCircle]);
        } else {
            setCircles([newCircle]);
        }
    };

    const handleTracing = (type) => {
        if (selectedFeature) {
            if (type === "down") handleDownTracing(selectedFeature.id);

            if (type === "up") handleUpwardTrace(selectedFeature.id);

            if (type === "start") setTraceBetween([selectedFeature]);

            if (type === "end")
                setTraceBetween((prevArray) => [...prevArray, selectedFeature]);

            if (type === "length") {
                if (points.length > 1 && !isMeasuring) {
                    clearLengthMapping();
                } else {
                    setIsMeasuring((prevState) => !prevState);
                    if (!isMeasuring)
                        setPoints((prevPoints) => [
                            ...prevPoints,
                            L.latLng(
                                selectedFeature.geometry.coordinates[1],
                                selectedFeature.geometry.coordinates[0]
                            ),
                        ]);

                    setMeasuringPoint(
                        L.latLng(
                            selectedFeature.geometry.coordinates[1],
                            selectedFeature.geometry.coordinates[0]
                        )
                    );
                }
            }
        } else {
            alert("Please select any point element.");
        }
    };

    const onFeatureEachData = (feature, layer, isTracing = false) => {

        const result = projectLineColor.find(item => item.project_id === feature.project_id);
        const initialStyle = {
            className: lineClassName,
            color: feature.id === selectedLineId ? 'red' : (isTracing ? tracingLineColor : (result ? result.color : lineColor)),
        };

        // Modify the style if the line is underground
        const lineProps = feature?.line_props ? JSON.parse(feature.line_props) : null;
        if (lineProps?.position === "underground") {
            initialStyle.dashArray = "8, 8";
        }

        // Set the initial style to the layer
        layer.setStyle(initialStyle);

        // Add click event listener
        layer.on({
            click: () => handleFeatureClick(feature),
        });
    };

    const formatPopupContent = (data) => {
        return Object.entries(data)
            .map(([key, value]) => {
                if (value === null) value = "N/A"; // Handling null values
                return `<p class=""><span class="text-secondary">${key.replace(
                    /_/g,
                    " "
                )}: </span><span class="word-break-all">${value}</span></p>`;
            })
            .join("");
    };

    const toggleLeftSidebar = () => {
        setSidebarVisible(!sidebarVisible);
        setblankMapOpen(false)
    };

    const handleLayerChange = (layerName) => {
        setActiveLayer(layerName);
    };

    function onEachGeomDataFeature(feature, layer) {
        // if (feature.properties && feature.properties.label) {
        //     layer.bindTooltip(feature.properties.label, {
        //         className: "custom-tooltip",
        //         permanent: true,  // Make the tooltip always visible
        //         direction: 'auto'  // Automatically positions the tooltip in a sensible way
        //     });
        // }
    }

    const toggleObjectElement = (elementName) => {
        setVisibleElements((prevState) => ({
            ...prevState,
            [elementName]: !prevState[elementName],
        }));
    };

    const initialZoom = 10; // Example initial zoom level
    const initialCenter = [20.5937, 78.9629]; // Example initial center [lat, lng]

    // Function to reset the map to its initial state
    const resetMapToInitialState = () => {
        mapRef.current.setView(initialCenter, initialZoom);
    };

    const handleGeneratePDF = async () => {
        // Reset map to initial state
        resetMapToInitialState();

        // Wait for the map to re-render to its initial state
        await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust delay as needed

        const mapContainer = document.querySelector(".main-content");

        // Temporarily set container size to fit entire map
        mapContainer.style.width = '100%'; // Adjust width if necessary
        mapContainer.style.height = 'auto'; // Adjust height if necessary

        // Capture the content with scaling
        domtoimage.toPng(mapContainer, {
            width: mapContainer.scrollWidth,
            height: mapContainer.scrollHeight,
            style: {
                transform: 'scale(1)', // No scaling needed here
                transformOrigin: 'top left',
            }
        }).then((dataUrl) => {
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: [210, 297], // A4 size in mm
            });

            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {
                const imgWidth = 210; // A4 width in mm
                const imgHeight = (img.height * imgWidth) / img.width;

                // Check if image height exceeds the PDF height
                if (imgHeight > 297) { // A4 height in mm
                    const scaleFactor = 297 / imgHeight;
                    pdf.addImage(dataUrl, 'PNG', 0, 0, imgWidth * scaleFactor, 297);
                } else {
                    pdf.addImage(dataUrl, 'PNG', 0, 0, imgWidth, imgHeight);
                }

                pdf.save("map.pdf");

                // Restore the original size of the container
                mapContainer.style.width = '';
                mapContainer.style.height = '';
            };
        }).catch((error) => {
        });
    };


    return (
        <>

            {/* <button onclick={setTaskNetworkVisiblea}>setTaskNetworkVisible</button> */}
            <TopNavbar sendDataToParent={handleDataFromChild} organizations={organizations} />
            <div className="app" style={{ position: "relative" }}>
                <div
                    className={`sidebar leftsidebar-container ${sidebarVisible ? "visible" : "hidden"}`}
                >
                    <LeftSidebar
                        sendDataToParent={handleDataFromChild}
                        setMapLayer={handleLayerChange}
                        updateCenter={calculateCenter}
                        addMerkers={setMarkers}
                        objectElements={visibleElements}
                        toggleObjectElements={toggleObjectElement}
                        handleGeneratePDF={handleGeneratePDF}
                        clearMap={clearLoadedMap}
                        setblankMapOpen={setblankMapOpen}
                        updateProjectColor={setProjectLineColor}
                        organizations={organizations}
                        divisions={divisions}
                        subDivisions={subDivisions}
                        setSubDivisions={setSubDivisions}
                        setDivisions={setDivisions}
                        setOrganizations={setOrganizations}
                        setSidebarVisible={setSidebarVisible}
                        setTaskNetworkVisible={setTaskNetworkVisible}
                        setTaskMapVisible={setTaskMapVisible}
                    />
                </div>
                <div className="main-content">
                    <button
                        className="toggle-switch"
                        onClick={toggleLeftSidebar}
                    >
                        <img className="bars-icon" src="menu.png" />
                    </button>
                    <div ref={ref} className={isPrinting ? 'enable-print' : ''}>
                        <>
                            {taskNetworkVisible === true ?
                                <ViewTaskMap /> &&
                                    taskMapkVisible === true ? <ViewTaskMap /> : <ViewTaskNetwork setTaskMapVisible={setTaskMapVisible} />
                                :
                                <MapContainer
                                    zoom={zoom}
                                    zoomControl={false}
                                    ref={mapRef}
                                    center={center}
                                    maxBounds={gujaratBounds}
                                    bounceAtZoomLimits={false}
                                    minZoom={8}
                                >
                                    <LayersControl>
                                        <ZoomControl position="bottomright" />
                                        <BaseLayer
                                            checked={activeLayer === "empty"}
                                            name="Empty"
                                        >
                                            <TileLayer
                                                url="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                                                maxZoom={30}
                                                attribution=""
                                            />
                                        </BaseLayer>

                                        <BaseLayer
                                            checked={activeLayer === "OpenStreetMap"}
                                            name="OpenStreetMap"
                                        >
                                            <TileLayer
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                maxZoom={30}
                                                attribution=""
                                            />
                                        </BaseLayer>

                                        <BaseLayer
                                            checked={activeLayer === "Satellite"}
                                            name="Satellite"
                                        >
                                            <TileLayer
                                                url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                                maxZoom={20}
                                                subdomains={["mt1", "mt2", "mt3"]}
                                            />
                                        </BaseLayer>

                                        <BaseLayer
                                            checked={activeLayer === "BingMaps"}
                                            name="Bing Maps"
                                        >
                                            <BingLayer
                                                bingkey="AuhiCJHlGzhg93IqUH_oCpl_-ZUrIE6SPftlyGYUvr9Amx5nzA-WqGcPquyFZl4L"
                                                type="AerialWithLabels"
                                                maxNativeZoom={19}
                                            />
                                        </BaseLayer>
                                    </LayersControl>
                                    {markers.map((marker) => (
                                        <div key={marker.value}>
                                            <Marker
                                                position={[marker.lng, marker.lat]}
                                                icon={customIcon}
                                            >
                                                <Tooltip
                                                    className="custom-tooltip"
                                                    direction="bottom"
                                                    permanent
                                                >
                                                    {marker.label}
                                                </Tooltip>
                                            </Marker>
                                            <GeoJSON
                                                data={JSON.parse(marker.feature)}
                                                onEachFeature={onEachGeomDataFeature}
                                            />
                                        </div>
                                    ))}
                                    <SetViewToBounds markers={markers} />
                                    {circles.map((circle, idx) => (
                                        <Circle
                                            key={idx}
                                            center={circle.latlng}
                                            radius={circle.radius}
                                            color={circle.color}
                                            fillColor={circle.fillColor}
                                            fillOpacity={circle.fillOpacity}
                                        />
                                    ))}
                                    {traceBetween.map((point, idx) => (
                                        <Circle
                                            key={idx}
                                            center={[
                                                point.geometry.coordinates[1],
                                                point.geometry.coordinates[0],
                                            ]}
                                            radius="10"
                                            color="red"
                                            fillOpacity="0"
                                        />
                                    ))}
                                    {isMeasuring && cursorPosition && (
                                        <Polyline
                                            positions={[measuringPoint, cursorPosition]}
                                            color="black"
                                        />
                                    )}
                                    {points.length > 1 && (
                                        <Polyline positions={points} color="black">
                                            {points.map((point, index) => {
                                                if (index === 0) return null;
                                                return (
                                                    <Tooltip
                                                        key={index}
                                                        direction="center"
                                                        offset={[0, 0]}
                                                        opacity={1}
                                                        permanent
                                                    >
                                                        <span>
                                                            Total Distance:{" "}
                                                            {totalDistance.toFixed(2)} km
                                                        </span>
                                                        <br />
                                                    </Tooltip>
                                                );
                                            })}
                                        </Polyline>
                                    )}
                                    {isMeasuring && <MyComponent />}
                                    {/* <FeatureGroup>
                            <EditControl
                                position="topright"
                                onCreated={handleCreated}
                                draw={{
                                rectangle: false,
                                circle: false,
                                circlemarker: false,
                                marker: false,
                                polyline: false,
                                }}
                            />
                            </FeatureGroup> */}
                                </MapContainer>
                            }
                        </>
                    </div>
                </div>
                {isRightSidebarVisible && (
                    <div className="sidebar right-sidebar-container">
                        {pointDataElements && (
                            <RightSidebar
                                selectedFeature={featureProperties}
                                setOpenedMenuOption={openedRightMenuOption}
                                sendTracingData={handleTracing}
                                networkDetails={networkDetails}
                                getidInRighSidebar={getidInRighSidebar}
                                images={images}
                                handleImageSelect={handleImageSelect}
                                markersNewData={markersNewData}
                                selectedMarker={selectedMarker}
                                clearImageFromMarker={clearImageFromMarker}
                                clearLastLine={clearLastLine}
                                clearNetwork={clearNetwork}
                                isDoubleclickIsActive={isDoubleclickIsActive}
                                lineProps={selectedLinePops}
                                selectDoubleImage={selectDoubleImage}
                                dataOnHandleEditClick={dataOnHandleEditClick}
                                setDataOnHandleEditClick={setDataOnHandleEditClick}
                                isEditing={isEditing}
                                setIsEditing={setIsEditing}
                                handleClickCancel={handleClickCancel}
                                getParentChildRelatedFeatures={getParentChildRelatedFeatures}
                                totalDistance1={totalDistance1}
                                setRightSidebarVisible={setRightSidebarVisible}
                                addChildNetwork={addChildNetwork}
                                parentId={parentID}
                                parentId1={parentID1}
                                updateNewNetworkPayloadById={updateNetworkById}
                                finalNetworkPayload={finalNetworkPayload}
                                resetMapview={clearNetwork}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
});

const SetViewToBounds = ({ markers }) => {
    const map = useMap(); // This hook provides the map instance

    useEffect(() => {
        if (markers.length > 0) {
            const bounds = L.latLngBounds(
                markers.map((marker) => L.latLng(marker.lng, marker.lat))
            );
            map.fitBounds(bounds);
            map.setZoom(10);
        }
    }, [markers, map]); // map is a stable object and does not cause re-renders

    return null; // This component does not render anything
};

export default MapComponent;
