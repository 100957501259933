import React from 'react';

const Ctpt = ({ formData, onFormDataChange }) => {

  const handleChange = (e) => {
    const { id, value } = e.target;
    onFormDataChange({
      ...formData,
      [id]: value
    });
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="support_type">Support Type *</label>
              <select
                className="form-select form-select-sm"
                id="support_type"
                value={formData.support_type}
                onChange={handleChange}
              >
                <option value="">Select support type</option>
                <option value="PSC">PSC</option>
                <option value="MS GIRDER">MS GIRDER</option>
                <option value="TABULAR">TABULAR</option>
                <option value="FRP">FRP</option>
                <option value="RAIL">RAIL</option>
                <option value="PLINTH">PLINTH</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="structure_type">Structure Type *</label>
              <select
                className="form-select form-select-sm"
                id="structure_type"
                value={formData.structure_type}
                onChange={handleChange}
              >
                <option value="">Select structure type</option>
                <option value="Double Pole">Double Pole</option>
                <option value="Single Pole">Single Pole</option>
                <option value="Plinth">Plinth</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="earthing_type">Earthing Type *</label>
              <select
                className="form-select form-select-sm"
                id="earthing_type"
                value={formData.earthing_type}
                onChange={handleChange}
              >
                <option value="">Select earthing type</option>
                <option value="GI Wire">GI Wire</option>
                <option value="GI Strip">GI Strip</option>
                <option value="Not Provided">Not Provided</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="ht_fuse_type">HT Fuse Type *</label>
              <select
                className="form-select form-select-sm"
                id="ht_fuse_type"
                value={formData.ht_fuse_type}
                onChange={handleChange}
              >
                <option value="">Select HT fuse type</option>
                <option value="Drop Out Fuse">Drop Out Fuse</option>
                <option value="Rewirable Fuse">Rewirable Fuse</option>
                <option value="HRC Fuse">HRC Fuse</option>
                <option value="Not Installed">Not Installed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="ctpt_type">CTPT Type *</label>
              <select
                className="form-select form-select-sm"
                id="ctpt_type"
                value={formData.ctpt_type}
                onChange={handleChange}
              >
                <option value="">Select CTPT type</option>
                <option value="Oil Type">Oil Type</option>
                <option value="DRY Type">DRY Type</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="ctpt_ratio">CTPT Ratio *</label>
              <select
                className="form-select form-select-sm"
                id="ctpt_ratio"
                value={formData.ctpt_ratio}
                onChange={handleChange}
              >
                <option value="">Select CTPT ratio</option>
                <option value="5-5">5-5</option>
                <option value="10-5">10-5</option>
                <option value="15-5">15-5</option>
                <option value="20-5">20-5</option>
                <option value="25-5">25-5</option>
                <option value="30-5">30-5</option>
                <option value="40-5">40-5</option>
                <option value="50-5">50-5</option>
                <option value="75-5">75-5</option>
                <option value="100-5">100-5</option>
                <option value="150-5">150-5</option>
                <option value="200-5">200-5</option>
                <option value="250-5">250-5</option>
                <option value="300-5">300-5</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_fencing">Fencing Available *</label>
              <select
                className="form-select form-select-sm"
                id="has_fencing"
                value={formData.has_fencing}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="scheme">Scheme</label>
              <select
                className="form-select form-select-sm"
                id="scheme"
                value={formData.scheme}
                onChange={handleChange}
              >
                <option value="">Select scheme</option>
                <option value="ND">ND</option>
                <option value="DISS">DISS</option>
                <option value="DDUJGY">DDUJGY</option>
                <option value="IPDS">IPDS</option>
                <option value="KHUSHY">KHUSHY</option>
                <option value="SSVY">SSVY</option>
                <option value="ZP-SCSP">ZP-SCSP</option>
                <option value="RDSS">RDSS</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="comm_date">Commissioning Date</label>
              <input
                placeholder="Enter commissioning date"
                type="date"
                id="comm_date"
                className="form-control form-control-sm"
                value={formData.comm_date}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Ctpt;
