import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import '../../../components/css/Sidebar.css'

const HtRoutePoint = ({ setRightSidebarVisible, selectedMarker, onSave, parentId, parentId1, getidInRighSidebar,onFormSave }) => {
  const [formData, setFormData] = useState({
    has_physical_marker: (selectedMarker?.options?.eleProp?.properties?.point_props.has_physical_marker || ''),
    has_joint: (selectedMarker?.options?.eleProp?.properties?.point_props.has_joint || ''),
    scheme: (selectedMarker?.options?.eleProp?.properties?.point_props.scheme || ''),
    position: (selectedMarker?.options?.eleProp?.properties?.line_props.position || ''),
    type: (selectedMarker?.options?.eleProp?.properties?.line_props.support_type || '')
  });

  const [showLoadAndStartButtons, setShowLoadAndStartButtons] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveAsCopyDisabled, setIsSaveAsCopyDisabled] = useState(true);

  // Load data from localStorage and check button visibility
  useEffect(() => {
    const savedData = localStorage.getItem('htRoutePointData');
    setShowLoadAndStartButtons(!!savedData);
  }, []);

  // Update save buttons state based on form data
  useEffect(() => {
    const allFieldsFilled = Object.values(formData).every(value => value !== '');
    setIsSaveDisabled(!allFieldsFilled);
    setIsSaveAsCopyDisabled(!allFieldsFilled);
  }, [formData]);

  // Clear form data
  const clearForm = () => {
    setFormData({
      has_physical_marker: '',
      has_joint: '',
      scheme: '',
      position: '',
      type: ''
    });
    const savedData = localStorage.getItem('htRoutePointData');
    setShowLoadAndStartButtons(!!savedData);
  };

  // Handle form changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const element = selectedMarker.options.eleProp;
    // Prepare the form data
    const requestBody = {
      "id": element.id,
      "type": "Feature",
      "geometry": element.geometry,
      "project_id": element.project_id,
      "properties": {
        "id": element.id,
        "parent_id": element.properties.parent_id,
        "point_type": "HT Route Point",
        "point_props": {
          structure_type: formData.structure_type,
          has_physical_marker: formData.has_physical_marker,
          has_joint: formData.has_joint,
          scheme: formData.scheme,
        },
        "line_props": {
          position: formData.position,
          type: formData.type
        },
        "data_type": "ht_location"
      }
    };
    onFormSave();
    onSave(requestBody);
    toast.success("Data Save Successfully!")
    setRightSidebarVisible(false)
    clearForm();
    // const myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: JSON.stringify(requestBody),
    //   redirect: "follow"
    // };

    // try {
    //   const response = await fetch("https://geo-tag.nividasoftware.com/api/create-new-network", requestOptions);
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }
    //   const result = await response.json();
    //   console.log("Response:", result);
    //   toast.success("Data Saved Successfully!");
    //   setRightSidebarVisible(false);
    //   clearForm(); // Clear the form after successful submission
    // } catch (error) {
    //   console.error("Error:", error);
    //   toast.error("Failed to save data. Please try again.");
    // }
  };
  // Handle save as copy
  const handleSaveAsCopy = () => {
    if (!isSaveAsCopyDisabled) {
      localStorage.setItem('htRoutePointData', JSON.stringify(formData));
       // Clear the form after saving as copy
    }
  };

  // Handle load last copy
  const handleLoadLastCopy = () => {
    const savedData = localStorage.getItem('htRoutePointData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  };

  // Handle Clear
  const handleStartNew = () => {
    clearForm();
  };

  return (
    <div>
      {/* Show Load Last Copy and Clear buttons if there is saved data */}
      {showLoadAndStartButtons && (
        <div className="mb-3">
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
              marginRight: '10px'
            }}
            onClick={handleLoadLastCopy}
          >
            Load Last Copy
          </button>
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
            }}
            onClick={handleStartNew}
          >
            Clear
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_physical_marker">Physical Marker Available *</label>
              <select
                className="form-select form-select-sm"
                id="has_physical_marker"
                value={formData.has_physical_marker}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_joint">Joint Available *</label>
              <select
                className="form-select form-select-sm"
                id="has_joint"
                value={formData.has_joint}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="scheme">Scheme</label>
              <select
                className="form-select form-select-sm"
                id="scheme"
                value={formData.scheme}
                onChange={handleChange}
              >
                <option value="">Select scheme</option>
                <option value="ND">ND</option>
                <option value="DISS">DISS</option>
                <option value="DDUJGY">DDUJGY</option>
                <option value="IPDS">IPDS</option>
                <option value="KHUSHY">KHUSHY</option>
                <option value="SSVY">SSVY</option>
                <option value="ZP-SCSP">ZP-SCSP</option>
                <option value="RDSS">RDSS</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="position">Position</label>
              <select
                className="form-select form-select-sm"
                id="position"
                value={formData.position}
                onChange={handleChange}
              >
                <option value="">Select position</option>
                <option value="Overhead">Overhead</option>
                <option value="Underground">Underground</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="type">Type</label>
              <input
                placeholder="Enter Type"
                type="text"
                id="type"
                className="form-control form-control-sm"
                value={formData.type}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            type="button"
            className="save-button"
            onClick={handleSaveAsCopy}
            disabled={isSaveAsCopyDisabled}
          >
            Save as Copy
          </button>
          <button
            type="submit"
            className="save-button"
            onClick={handleSubmit}
            disabled={isSaveDisabled}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default HtRoutePoint;
