import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Download, ArrowRight } from "react-bootstrap-icons";

const ModalData = ({
    show,
    fullscreen,
    setShow,
    setSidebarVisible,
    visibleElements,
    selectedProjects,
    setblankMapOpen,
}) => {
    const [selectedSize, setSelectedSize] = useState("A4");
    const [toggleElements, setToggelElements] = useState(visibleElements);
    const [toggleStates, setToggleStates] = useState({});

    const handleButtonClick = (size) => {
        setSelectedSize(size);
    };

    useEffect(() => {
        const initialStates = selectedProjects.reduce(
            (acc, item) => ({
                ...acc,
                [item.project_id]: {
                    label: item.label,
                    isToggled: true,
                },
            }),
            {}
        );
        setToggleStates(initialStates);
    }, [selectedProjects]);

    const handleToggleChange1 = (projectId) => {
        setToggleStates((prev) => ({
            ...prev,
            [projectId]: {
                ...prev[projectId],
                isToggled: !prev[projectId].isToggled,
            },
        }));
    };
    const handleToggleChange = (key) => {
        setToggelElements((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const buttonStyles = (size) => {
        const sizes = { A0: 60, A1: 55, A2: 50, A3: 45, A4: 40 }; // Button sizes
        const fontSize = {
            A0: "18px",
            A1: "16px",
            A2: "14px",
            A3: "12px",
            A4: "10px",
        }; // Font sizes

        return {
            width: `${sizes[size]}px`,
            height: `${sizes[size]}px`,
            fontSize: fontSize[size],
            backgroundColor: size === selectedSize ? "#007bff" : "white",
            color: size === selectedSize ? "white" : "black",
            border: `1px solid ${
                size === selectedSize ? "#007bff" : "#d7d7d7"
            }`,
            borderRadius: "5px",
            margin: "0 15px 0 0",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.3s, color 0.3s, border-color 0.3s",
        };
    };

    const handleClose = () => {
        setShow(false);
        setSidebarVisible(true);
    };
    const handleOpenMap = () => {
        setShow(false);
        setblankMapOpen(true);
        // setSidebarVisible(true);
    };
    return (
        <>
            <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Download />{" "}
                        <span style={{ marginLeft: "10px" }}>
                            {" "}
                            Download Map
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* First Label and Box */}
                    <div
                        style={{
                            marginBottom: "20px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <ArrowRight style={{ marginRight: "10px" }} />{" "}
                        {/* Right arrow icon */}
                        <label
                            style={{
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            Feeders
                        </label>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            padding: "10px",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            transition: "opacity 0.5s ease-in-out",
                            opacity: show ? 1 : 0,
                            backgroundColor: "#f9f9f9",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                padding: "15px 0",
                                flexDirection: "column",
                            }}
                        >
                            {selectedProjects?.map((item, index) => (
                                <div
                                    key={item.project_id}
                                    style={{
                                        marginBottom: "15px",
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Form.Check
                                        type="switch"
                                        id={`toggle-${item.project_id}`}
                                        checked={
                                            toggleStates[item.project_id]
                                                ?.isToggled || false
                                        }
                                        onChange={() =>
                                            handleToggleChange1(item.project_id)
                                        }
                                        style={{ marginRight: "10px" }}
                                    />
                                    <div>
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                marginRight: "10px",
                                            }}
                                        >
                                            {index + 1}.
                                        </span>
                                        {item?.label || ""} ({item?.project_id})
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Second Label and Buttons in a Box */}
                    <div style={{ marginTop: "30px" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <ArrowRight style={{ marginRight: "10px" }} />
                            <label style={{ fontSize: "16px" }}>
                                Select Layout (Page Size)
                            </label>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#f9f9f9",
                                flexWrap: "wrap",
                            }}
                        >
                            {["A0", "A1", "A2", "A3", "A4"].map((size) => (
                                <button
                                    key={size}
                                    onClick={() => handleButtonClick(size)}
                                    style={buttonStyles(size)}
                                >
                                    {size}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Third Section */}
                    <div style={{ marginTop: "30px" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <ArrowRight style={{ marginRight: "10px" }} />
                            <label style={{ fontSize: "16px" }}>
                                Set below options to fulfill your need
                            </label>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                backgroundColor: "#f9f9f9",
                            }}
                        >
                            <h5>HT Network</h5>
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginBottom: "20px",
                                }}
                            >
                                <div>
                                    {Object.keys(toggleElements).map(
                                        (value, key) => {
                                            return (
                                                <Form.Check
                                                    type="switch"
                                                    id="toggle-allHTNetwork"
                                                    checked={
                                                        toggleElements[value]
                                                    }
                                                    onChange={() =>
                                                        handleToggleChange(
                                                            value
                                                        )
                                                    }
                                                    label={
                                                        value
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        value
                                                            .slice(1)
                                                            .replace(/_/g, " ")
                                                    }
                                                    style={{
                                                        marginBottom: "10px",
                                                    }}
                                                />
                                            );
                                        }
                                    )}
                                </div>
                                {/* <div>
                                    <ReactToPrint
                                        content={reactToPrintContent}
                                        documentTitle="AwesomeFileName"
                                        onAfterPrint={handleAfterPrint}
                                        onBeforeGetContent={handleOnBeforeGetContent}
                                        onBeforePrint={handleBeforePrint}
                                        removeAfterPrint
                                        trigger={reactToPrintTrigger}
                                    />
                                    {loading && <p className="indicator">onBeforeGetContent: Loading...</p>}
                                    <ComponentToPrint ref={componentRef} text={text} />
                                    </div> */}
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                            }}
                        >
                            <button
                                onClick={handleOpenMap}
                                style={{
                                    padding: "10px 5px",
                                    width: "200px",
                                    border: "none",
                                    background: "#4f6cff",
                                    color: "white",
                                    borderRadius: "8px",
                                    margin: "20px",
                                }}
                            >
                                Download
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalData;
