import React from 'react';
import { MDBDataTable } from 'mdbreact';
import { FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const ViewTaskNetwork = ({setTaskMapVisible}) => {
const navigate = useNavigate()
const handleEyeClick =()=>{
// navigate('/view-task-map')
setTaskMapVisible(true)
}

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150
      },
      {
        label: "Project ID",
        field: "projectid",
        sort: "asc",
        width: 150
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100
      }
    ],
    rows: [
      {
        no: "1",
        name: "Tiger Nixon",
        projectid: "P001",
        status: "Active",
        action: <FaEye onClick={handleEyeClick} style={{cursor: "pointer"}}/>
      },
      {
        no: "2",
        name: "Garrett Winters",
        projectid: "P002",
        status: "Inactive",
        action: <FaEye onClick={handleEyeClick} style={{cursor: "pointer"}}/>
      },
      // Add more rows as needed
    ]
  };

  return (
    <div style={{ margin: '70px 20px' }}>
      <MDBDataTable striped bordered hover data={data} />
    </div>
  );
};

export default ViewTaskNetwork;
